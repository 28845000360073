import React from 'react'
import HeroForm from './HeroForm'
import final_1 from '../assets/Final_1.mp4'


const Herosection = () => {
  return (
    <>
      <div className='page-width flex items-start justify-center gap-10 md:justify-between flex-wrap pt-2 md:pt-20'>
        <div className='md:max-w-[50%]'>
          <p className='body-text text-xs md:text-sm bodytext pb-[10px]  '>Marketing Advisory & Execution Partners</p>
          <h1 className=' text-4xl '>
            Unlock your Growth&nbsp;
            <br className=' hidden md:block'/>
            Potential 
          </h1>
          <video playsInline="true" autoPlay={true} className='border rounded-lg mt-8 md:max-w-[27.5rem] ' width="100%" height="100%" controls={false} loop={true} muted={true}>
            <source src={final_1} type="video/mp4" />
          </video>
        </div>
        <HeroForm />

      </div>
      <div className='flex items-center justify-center md:justify-between pt-9  md:pt-24 page-width'>
        <ul className=' p-0 flex flex-wrap list-none'>
          <li>
            <a className='body-text text-text-3' href='#'>Communications&nbsp;-&nbsp;</a>
          </li>
          <li>
            <a className='body-text text-text-3 ' href='#'>Production&nbsp;-&nbsp;</a>
          </li>
          <li>
            <a className='body-text text-text-3 ' href='#'>Tech&nbsp;-&nbsp;</a>
          </li>
          <li>
            <a className='body-text text-text-3 ' href='#'>Growth</a>
          </li>
        </ul>
        <p className='body-text hidden md:block md:pl-96 text-text-3'>MUM, INDIA</p>
        <p className='body-text hidden md:block text-text-3'>&copy; BAMBOO DIGITAL 2023</p>
      </div>
    </>
  )
}

export default Herosection
