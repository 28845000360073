import React from 'react';
import Careimg from '../assets/carimg.webp';
import CarrierHero from '../components/CarrierHero';
import Joblist from '../components/Joblist'


const Careers = () => {
  return (
    <section className='page-width'>
      <CarrierHero />
      <Joblist />
      
    </section>
  );
};

export default Careers;
