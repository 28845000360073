import React, { useState } from 'react';

const Service_card = ({ heading, para, url }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
      <a href={url}
        className={`service_card hover:text-primary hover:border-primary hover:bg-[#2C2F24] ease-in-out rounded-xl md:rounded-lg bg-[#2c2829] border text-white border-[#6A6264] md:max-w-[48%] p-4 md:p-6 flex gap-4 items-end justify-between ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div>
          <h4 className='text-base md:text-2xl font-medium'>
            {heading}
          </h4>
          <p className='body-text text-[#CECACB] pt-5 md:pt-14'>
            {para}
          </p>
        </div>
        <div>
          <svg
            className={`icons w-6 md:w-[40px] h-6 md:h-[40px] ${isHovered ? 'hidden' : ''}`}
            width="40" height="40" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.6667 25L26.6667 20M26.6667 20L21.6667 15M26.6667 20H13.3333M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>

          <svg
            className={`icons w-6 md:w-[40px] h-6 md:h-[40px] ${isHovered ? '' : 'hidden'}`}
            width="40" height="40" viewBox="0 0 40 40" fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25 22.5V15M25 15H17.5M25 15L15.0003 24.9999M35 20C35 28.2843 28.2843 35 20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20Z"
              stroke="#AEC957" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            />
          </svg>
        </div>
      </a>

  );
}

export default Service_card;
