import React from 'react'
import WorksCard from '../components/WorksCard'

const Works = () => {
  React.useEffect(() => {
    document.title = 'Works - Bamboo Digital';
  }, []);
  return (
    <section className='page-width'>
      <WorksCard/>
    </section>
  )
}

export default Works
