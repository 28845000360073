import React from 'react';
import image1 from "./../assets/Copy of bamboo digital (1) (1) 1.svg";
import insta from "./../assets/insta.svg";
import linkedin from "./../assets/linkedin.svg";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

const Footer = () => {
  return (
    <footer className='page-width relative z-10 py-10 md:py-[100px] bg-[#FCFCFC] text-sm'>
      <img className=' max-w-[91px] ' src={image1} alt="Image 1" />
      <br />
      <div className=' justify-between flex-col-reverse md:flex-row flex-wrap flex gap-5 text-text-2 text-sm '>
        <div className=' w-full md:w-[30%] '>
          <p className='text-netural font-medium text-base'>
            Copyright &copy; {new Date().getFullYear()} Bamboo Digital
          </p>
          <br />
          <p className='max-w-sm text-text-2 text-sm'>
            Address: Office no. 108, 1st Floor, Wing-C, Trade World Premises Cooperative Society Ltd, Kamla Mills Compound, Kamla City, Senapati Bapat Marg, Lower Parel, Mumbai - 400013
          </p>
          <br />
          <p className='text-text-2 text-sm'>
            Call <a href="tel:+919136402590">+91 91364 02590</a>
            <br />
            Contact - <a href="mailto:info@bamboodigital.in">info@bamboodigital.in</a>
          </p>
        </div>
        <div className='flex w-full flex-wrap gap-2 md:w-[60%] justify-between '>
          <div className=' w-[40%] md:w-auto'>
            <p className='text-netural w-full font-medium text-base pb-2 '>Bamboo Digital</p>
            <ul>
              <li className="text-sm pb-2"  >
                <Link className="text-sm"  to="/about">About</Link>
              </li>
              <li className="text-sm pb-2">
                <Link className="text-sm" to="/services">Services</Link>
              </li>
              <li className="text-sm pb-2">
                <Link className="text-sm" to="/works">Works</Link>
              </li>
              <li className="text-sm pb-2">
                <Link className="text-sm" to="/carrers">Career</Link>
              </li>
              <li className="text-sm pb-2">
                <Link className="text-sm" to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className='w-[40%] md:w-auto'>
            <div>
              <p className='text-netural pb-2 font-medium text-base'>Legal</p>
              <ul>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li className="text-sm pb-2">
                  <Link className="text-sm" to="/cookie-policy">Cookie Policy</Link>
                </li>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/terms-of-use">Terms of Use</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='w-[40%] pb-2 md:w-auto'>
            <div>
              <p className='text-netural font-medium text-base'>Services</p>
              <ul>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/services/communication">Communication</Link>
                </li>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/services/production">Production</Link>
                </li>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/services/tech">Tech</Link>
                </li>
                <li className="text-sm pb-2" >
                  <Link className="text-sm" to="/services/media-labs">Media Labs</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className='flex gap-[10px]'>
        <button className='px-5 py-3 btn bg-[#8FA937] rounded-lg text-white focus:outline-none focus:ring focus:ring-primary hover:bg-transparent hover:border border-primary'>
          Contact Us
        </button>
        <button className='bg-bg-1 p-3 rounded-lg'>
          <a href="https://www.instagram.com/bamboodigital.mate/">
            <img src={insta} alt="Instagram" />
          </a>
        </button>
        <button className='bg-bg-1 p-3  rounded-lg'>
          <a href="https://in.linkedin.com/company/bamboo-digital-mate">
            <img src={linkedin} alt="LinkedIn" />
          </a>
        </button>
      </div>
    </footer>
  )
}

export default Footer;
