import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

const SerCard = ({linkTo,id, img, heading, para}) => {
  return (
     <Link className="text-sm" to={linkTo}>
    <li className='ser-card bg-secondary text-white'>
      <img className='pb-10 w-1/3' src={img} alt="Company Logo" />
      <h4 className='text-base md:text-3xl pb-5'>
      {heading}
      </h4>
      <p className='text-xs md:text-base'>
      {para}
        </p>
    </li>
        </Link>
  );
};

export default SerCard;
