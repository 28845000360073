// src/firebase.js (or wherever you want to configure Firebase)
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDoraVVdEeSYyRbpmpp8vLwkRDDeOIhjdY",
  authDomain: "bamboodigitalreact.firebaseapp.com",
  projectId: "bamboodigitalreact",
  storageBucket: "bamboodigitalreact.appspot.com",
  messagingSenderId: "893092733026",
  appId: "1:893092733026:web:689a5a7402d145c64f313b",
  measurementId: "G-BHKKV97P80"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
