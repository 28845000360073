import React from 'react';
const WorkDetail = ({ pageHeading, heading, img1, desc, services, sector, img2, img3, img4 }) => {
  return (
    <div className="page-width">
      <div>
        <div>
          <h1 className='py-5 text-3xl font-medium leading-2'>{pageHeading}</h1>
          {img1 && <img
            src={img1}
            alt={`thumbnail of ${heading}`}
            className="w-full rounded-lg h-[600px] object-cover flex-shrink-0 rounded-4 border border-gray-300 bg-gray-200"
          />}
        </div>
        <div className='flex flex-col md:flex-row-reverse items-start'>
          <div className="md:w-[50%] mt-12 ">
            <h2 className="text-2xl font-medium text-gray-900 font-inter">{heading}</h2>

            <p className="mt-5" style={{ color: '#667085', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '20px', letterSpacing: '-0.32px' }}>
              {desc.map((des, index) => (
                    <><p key={index}>{des}</p><br /></>
                  ))}
            </p>
            <button className="bg-transparent pb-2 border-x-none border-b-2 border-secondary border-solid outline-none mt-10 flex items-center gap-4 hidden md:block">
              <a className='flex gap-1' to='/about'>
                <span className='roboto'>EXPLORE BAMBOO DIGITAL</span>
                <span>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16602 10H15.8327M15.8327 10L10.8327 5M15.8327 10L10.8327 15"
                      stroke="#101828"
                      stroke-width="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </a>
            </button>


          </div>


          <div className='flex md:flex-row flex-1'>
            <div className="md:w-[50%]  mt-12 ">
              <ul>
                <li>
                  <h4 className='text-2xl font-medium text-gray-900 font-inter '>
                    Services
                  </h4>
                  <ul className="mt-3" style={{ color: '#667085', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '20px', letterSpacing: '-0.32px' }}>

                  {services.map((service, index) => (
                    <li key={index}>{service}</li>
                  ))}

                  </ul>
                </li>
                <li>
                  <h4 className='text-2xl font-medium text-gray-900 font-inter mt-6'>
                    Sector
                  </h4>
                  <ul className='mt-3'>
                    <li className="mt-3" style={{ color: '#667085', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '20px', letterSpacing: '-0.32px' }}>
                      {sector}
                    </li>

                  </ul>
                </li>
              </ul>
            </div>

          </div>


        </div>
        {/* <ImageWorkDetail /> */}
        <div className=' mt-[80px] '>
          {img2 && <img
            src={img2}
            alt=""
            className="w-full h-[200px] object-cover sm:h-[600px] flex-shrink-0 rounded-lg border border-gray-300 bg-gray-200"
          />}

        </div>
        <div className='flex flex-row gap-5 pt-[30px]'>
          {img3 && <img
            src={img3}
            alt="thumbnail of project done by bamboo digital"
            className="w-[165px] h-[200px] sm:w-[600px] sm:h-[400px]  rounded-lg border object-cover border-gray-300 bg-gray-200"
          />}
          {img4 && <img
            src={img4}
            alt="thumbnail of project done by bamboo digital"
            className="w-[165px]  h-[200px] sm:w-[600px] sm:h-[400px] flex-shrink-0 rounded-lg object-cover border border-gray-300 bg-gray-200"
          />}
        </div>
      </div>
    </div>
  );
}

export default WorkDetail;
