import React from 'react'

const ProjectCard = ({ name, tags, img }) => {
  return (
    <div className=' text-white'>
      <div className=' rounded-t-[8px] border border-[#6A6264] ' >
        <img src={img} className=' ' />
        {/* <h2 className='h1 text-[33px]  py-4 font-normal'>{name}</h2> */}
      </div>
      <div className='rounded-b-[8px] border border-[#6A6264]  p-6 flex items-center justify-between'>
        <div className='flex items-center gap-4 flex-wrap'>
          <ul className='flex items-center gap-2'>
            {tags && tags.map((tag, index) => (
              <li key={index} className='border  w-fit px-4 py-3 rounded-full roboto text-xs md:text-sm'>
                {tag}
              </li>
            ))}
          </ul>
        </div>
        <a href='#' className='border  px-4 py-3 rounded-full  text-xs md:text-sm'>
          +1
        </a>
      </div>
    </div>
  )
}

export default ProjectCard
