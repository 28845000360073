import React from 'react'

const LookingCard = ({heading,des, url}) => {
    return (
        <div>
            <div className='md:p-10 p-5 border  rounded-t-lg w-full'>
                <div className=' w-[10px] h-[10px] rounded-full bg-[#D9D9D9]'>
                </div>
                <h3 className=' text-white text-3xl pt-10 md:w-[59%]'>
                    {heading}
                </h3>
            </div>
            <a href={url} className='px-5 md:px-10 py-5 bg-[#2c282a] border  rounded-b-lg flex items-center justify-between'>
                    <span>
                        {des}
                    </span>
                    <span>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.16797 10H15.8346M15.8346 10L10.8346 5M15.8346 10L10.8346 15" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </span>
                </a>
        </div>
    )
}

export default LookingCard
