import React, { useState } from 'react';

const MenuItem = ({ title, subMenuItems, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuItem = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className=" mb-8 md:m-auto md:absolute inline-block w-full z-10 px-6 py-2 border rounded-[20px] ">
      <div onClick={toggleMenuItem} className={`${isOpen ? 'pb-[30px]' : ''
        } cursor-pointer flex items-center justify-between  font-medium`}>
        <span>
          {title}
        </span>
        <span className={`${isOpen ? 'rotate-180' : ''
          }`} >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10 4.16699V15.8337M10 15.8337L5 10.8337M10 15.8337L15 10.8337" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </div>
      <div
        className={`${isOpen ? 'block' : 'hidden'
          } `}
      >
        <ul>
          {subMenuItems.map((item, index) => (
            <li key={item.id}>
              <a className="block  py-[10px] cursor-pointer" href={`#0${index + 1}`}>
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MenuItem;
