import React from 'react'

const Cookie = () => {
  React.useEffect(() => {
    document.title = 'Cookie Policy - Bamboo Digital';
  }, []);
  return (
    <div className=' pt-10  md:pt-[100px]'>
      <section className='page-width'>
      <h1>Cookie Policy</h1>
    <p>Please note that this privacy statement will be updated from time to time.</p>
    <br/>
    <p>We can place cookies and similar technology on your device, including mobile devices, according to your preferences. The following information may be collected through cookies or similar technology: your unique device identifier, mobile device IP address, information about your device’s operating system, mobile carrier, and location information (to the extent permissible under applicable law).</p>
    <br/>
    <strong>What are cookies?</strong>
    <p>Cookies are text files or bits of information downloaded to your computer or smartphone when you visit a site and allow a site to recognize your device. Cookies managed by Bamboo Digital are called “first-party cookies,” whereas cookies from third parties are called “third-party cookies,” as explained below.</p>
    <br/>
    <strong>Why do We Use Cookies and Similar Technologies?</strong>
    <p>Cookies serve a valuable purpose by enhancing your browsing experience. They enable efficient navigation between pages, remember your preferences, and contribute to improved user experiences. Additionally, cookies enhance the relevance of online advertisements by tailoring them to your interests. Furthermore, they assist us in analyzing website usage (analytics cookies) and track social media interactions on our websites and online content (e.g., links to social media sites, like buttons, etc.).</p>
    <br/>
    <strong>Does Bamboo Digital use cookies for marketing and analytics?</strong>
    <p>Yes, we may utilize data from our cookies to analyze user behavior and deliver content and offers tailored to your profile by applicable laws in specific jurisdictions.</p>
    <br/>
    <p>In certain instances, we may connect cookie data (including information obtained through cookies from our advertisements on third-party websites) to identifiable individuals. For instance:</p>
    <ul>
        <li>If we send you a targeted email containing web beacons, cookies, or similar technologies, we can determine whether you opened, read, or deleted the message.</li>
        <li>When you click on a link in a marketing email from Bamboo Digital, we will also employ a cookie to record the pages you visit and the content you access on our websites, even if you are not registered or logged in.</li>
    </ul>
    <br/>
    <strong>Do we use third-party cookies?</strong>
    <p>Yes, we use third-party cookies from companies like Facebook, Google, Microsoft, and others for web analytics and ad targeting. These cookies collect data on your site interactions, such as page visits and link clicks, but only while you're on our website. For details on how they handle your data, please see their privacy policies: Facebook, Google (including YouTube), Microsoft, and others.</p>
    <br/>
    <strong>Does Bamboo Digital use any tracking technologies similar to cookies?</strong>
    <p>We may employ web beacons and similar technologies, including conversion pixels, on our website, in marketing emails, newsletters, and affiliated sites. These tools help us track message openings and link clicks, enhancing our understanding of website activity. Web beacons complement cookies in monitoring online behavior. Likewise, conversion pixels, small codes on web pages, increment conversion counts when pages are visited.</p>
    <br/>
    <strong>What if I Don’t Want Cookies or Similar Tracking Technologies?</strong>
    <p>You can adjust your cookie settings through our cookie consent manager. If you want to remove existing cookies from your device, you can do this using your browser options. You can use our cookie consent manager to block future cookies from being placed on your device.</p>
    <p>Please bear in mind that deleting and blocking cookies may have an impact on your user experience.</p>
    <br/>
    <strong>What types of cookies does the site use?</strong>
    <p>The cookies used on Bamboo Digital sites have been categorized as per the table below. However, it is important to note that not all cookies may be used in all jurisdictions or websites. A list of the categories of cookies used on this website is set out below.</p>
    <table>
        <tr>
            <th>Category</th>
            <th>Description</th>
        </tr>
        <tr>
            <td>Strictly Necessary Cookies</td>
            <td>These cookies are essential to enable you to move around the site and use its features, such as accessing secure areas of the site. The services you have asked for cannot be provided without these cookies.</td>
        </tr>
        <tr>
            <td>First-party analytics cookies</td>
            <td>These cookies enable us to use data analytics to enhance our website's performance and deliver more pertinent content. They do not gather information identifying individual visitors that is accessible to us. Personal information is only shared with external third parties when we employ service providers who cannot use the data for their purposes. Such providers include Adobe's Analytics, Target, Audience Manager, and Contentsquare and Demandbase.</td>
        </tr>
        <tr>
            <td>Performance cookies</td>
            <td>Performance cookies, often from our third-party partners or on their behalf, gather data on your visit to the Bamboo Digital website. This includes which pages you visit most frequently and whether you encounter any web page errors. These cookies do not identify individual visitors. All data collected is anonymous and solely used to enhance website functionality. Third-party vendors may use this data to improve their services and offerings.</td>
        </tr>
        <tr>
            <td>Functionality cookies</td>
            <td>These cookies enable the website to remember your preferences (like username, language, and region) for enhanced, personalized features. They do not track your activity on other websites or collect information for advertising purposes. These are categorized as advertising and social media cookies.</td>
        </tr>
    </table>
    <br/>
    <p>Advertising and social media cookies, including web beacons and tracking technologies, serve several purposes. They deliver more relevant ads based on your interests, limit ad frequency, assess campaign effectiveness, retarget our content, and analyze post-advertisement behavior. Advertising networks typically place these cookies with the site operator's consent. They remember your site visits and often link to external organization-provided site functions. This can influence the content and messages you encounter on other websites.</p>
    <br/>
    <p>If you have any questions now or during your visit, please submit your request through our Contact Us form. You can adjust your cookie settings anytime from our cookie consent manager.</p>

      </section>
    </div>
  )
}

export default Cookie
