import React from 'react';

import nf from './../assets/Group 8.webp';

const PageNotFound = () => {
  return (
    <div className='page-width ' >
      <div className="flex   justify-between  gap-5 items-start flex-col-reverse  md:flex-row  lg:flex-row">
       <div className=' lg: mt-20 text-center lg:text-left'>
        <h1 className="text-2xl lg:text-3xl font-medium leading-10 lg:leading-[48px] tracking-tight text-inter">Nothing Found</h1>
        <p className="text-inter text-base font-normal leading-[22px] text-gray-500 lg:justify-start">Oops! The page you're looking for couldn't be <br></br>found. Please check the URL and try again.</p>
      </div>
      <div className='w-100 h-100 lg:w-400  flex justify-between lg:justify-end   mt-6 lg:m-8'>
        <img
          src={nf}
          alt='Nothing Found'
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>

    </div>
  );
}

export default PageNotFound;
