import React from 'react'
import a1 from "./../assets/Client Logos/Allen Solly.webp"
import a2 from "./../assets/Client Logos/Amazon.webp"
import a3 from "./../assets/Client Logos/Anerican Eagel.webp"
import a4 from "./../assets/Client Logos/Asian Paints.webp"
import a5 from "./../assets/Client Logos/Casagranda.webp"
import a6 from "./../assets/Client Logos/Disney Hotstar.webp"
import a7 from "./../assets/Client Logos/Jio mart.webp"
import a10 from "./../assets/Client Logos/Louiis Philippe.webp"
import a11 from "./../assets/Client Logos/Mahindra.webp"
import a12 from "./../assets/Client Logos/Pepperfry.webp"
import a13 from "./../assets/Client Logos/Peter England.webp"
import a14 from "./../assets/Client Logos/Rustomjee.webp"
import a15 from "./../assets/Client Logos/The collective.webp"
import a16 from "./../assets/Client Logos/Van Heusen.webp"
import a17 from "./../assets/Client Logos/Zayn Myza.webp"


const Client = () => {
    return (
        <div className=' bg-secondary'>
        <div className=' bg-white rounded-t-[20px] md:rounded-t-[40px] '>
            <div className=''>
                <div className='page-width'>
                    <p className=' pt-10 pb-8 text-base font-normal md:text-[32px]  md:leading-9 md:tracking-wider  md:py-[100px]'>
                        <span className=' text-primary inline-flex items-baseline gap-2 md:gap-4'>
                            <svg className=' w-4 md:w-8 h-4 md:h-auto' width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.5 17H37.5M37.5 17L22.5 2M37.5 17L22.5 32" stroke="#AEC957" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Bamboo Digital&nbsp;
                        </span>
                        <span>
                            is a collective of individuals, who are passionate for visionary solutions and delivering dynamic products. We recognize that implementing creative vision is a privilege shared only by those that can be ruthless in achieving goals.
                        </span>
                    </p>

                    <p className='body-text text-text-3 pb-8 md:pb-[100px]'>
                        Trusted by pioneers
                        <br />
                        and industry leaders alike
                    </p>


                </div>
            </div>
            <div className=' flex flex-col gap-10 py-10 md:py-0 md:pb-[100px]'>
                <div class="logos">
                    <div class="logos-slide">
                    <img className='slide' src={a1} alt='Allen Solly' />
                            <img className='slide' src={a2} alt='Amazon' />
                            <img className='slide' src={a3} alt='Anerican Eagel' />
                            <img className='slide' src={a4} alt='Asian Paints' />
                            <img className='slide' src={a5} alt='Casagranda' />
                            <img className='slide' src={a6} alt='Disney Hotstar' />
                            <img className='slide' src={a7} alt='Jio mart' />
                            <img className='slide' src={a10} alt='Louiis Philippe' />
                            <img className='slide' src={a11} alt='Mahindra' />
                            <img className='slide' src={a12} alt='Pepperfry' />
                            <img className='slide' src={a13} alt='Peter England' />
                            <img className='slide' src={a14} alt='Rustomjee' />
                            <img className='slide' src={a15} alt='The collective' />
                            <img className='slide' src={a16} alt='Van Heusen' />
                            <img className='slide' src={a17} alt='Zayn Myza' />
                            <img className='slide' src={a1} alt='Allen Solly' />
                            <img className='slide' src={a2} alt='Amazon' />
                            <img className='slide' src={a3} alt='Anerican Eagel' />
                            <img className='slide' src={a4} alt='Asian Paints' />
                            <img className='slide' src={a5} alt='Casagranda' />
                            <img className='slide' src={a6} alt='Disney Hotstar' />
                            <img className='slide' src={a7} alt='Jio mart' />
                            <img className='slide' src={a10} alt='Louiis Philippe' />
                            <img className='slide' src={a11} alt='Mahindra' />
                            <img className='slide' src={a12} alt='Pepperfry' />
                            <img className='slide' src={a13} alt='Peter England' />
                            <img className='slide' src={a14} alt='Rustomjee' />
                            <img className='slide' src={a15} alt='The collective' />
                            <img className='slide' src={a16} alt='Van Heusen' />
                            <img className='slide' src={a17} alt='Zayn Myza' />
                            
                    </div>
                </div>
               
            </div>
        </div>
    </div>
)
}


export default Client
