import React, { useState } from 'react';

const Accordin = ({ id, title, content, isLastItem }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuItem = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div id={id} onClick={toggleMenuItem} className='cursor-pointer'>
        <div className='flex items-center justify-between'>
          <div className='flex items-start gap-5'>
            <span className='font-medium'>{id}</span>
            <h1 className='font-normal'>{title}</h1>
          </div>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
              <path
                d="M8 12.5L16 20.5L24 12.5"
                stroke="#98A2B3"
                stroke-width={2.66667}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className={`${isOpen ? 'block' : 'hidden'}  text-sm md:text-base cursor-pointer pt-8 md:pt-20 md:ml-[18%] flex flex-row-reverse justify-items-end`}>
          {content}
        </div>
      </div>
      {!isLastItem && <div className=' py-10 md:py-20'><hr /></div>}
    </div>
  );
};

export default Accordin;
