import React, { useState } from 'react';
import { getFirestore, collection, addDoc } from "firebase/firestore";

function HeroForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    phone: '', // Add the "phone" field
  });

  const [ticketNumber, setTicketNumber] = useState(null);

  const generateTicket = () => {
    const min = 1000; // Minimum ticket number
    const max = 9999; // Maximum ticket number
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    setTicketNumber(randomNumber);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = async () => {
    const apiKey = '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'; // Replace with your actual API key
    const url = 'https://api.brevo.com/v3/smtp/email';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify({
          sender: {
            name: 'Bamboo Digital',
            email: 'info@bamboodigital.in',
          },
          to: [
            {
              email: formData.email,
              name: formData.name,
            },
          ],
          subject: 'Thank You for Contacting Us',
          htmlContent: `
            <!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Thank You for Your Inquiry</title>
              <style>
                /* Add your email styles here */
                body {
                  font-family: Arial, sans-serif;
                  background-color: #f0f0f0;
                  margin: 0;
                  padding: 0;
                }
                .container {
                  max-width: 600px;
                  margin: 0 auto;
                  padding: 20px;
                  background-color: #ffffff;
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                  border-radius: 8px;
                }
                h1 {
                  font-size: 24px;
                  font-weight: bold;
                  margin-bottom: 20px;
                }
                p {
                  font-size: 16px;
                  line-height: 1.5;
                  margin-bottom: 20px;
                }
                .contact-info {
                  margin-top: 20px;
                  font-size: 14px;
                }
              </style>
            </head>
            <body>
              <div class="container">
                <h1>Hello Bamboo Digital team new ${formData.name},</h1>
                <p>We're delighted to receive your inquiry about our services. Rest assured, you've come to the right place, and we'll get in touch with you soon.</p>
                <p>We look forward to the possibility of working together.</p>
                <div class="contact-info">
                  <p>Best Regards,</p>
                  <p>Bamboo Digital Pvt. Ltd.</p>
                  <p>Mob. +91 91364 02590</p>
                  <p>Email: info@bamboodigital.in</p>
                </div>
              </div>
            </body>
            </html>
          `,
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        // console.log('Email sent:', responseData);
      } else {
        // console.error('Error sending email:', responseData);
      }
    } catch (error) {
      // console.error('Error sending email:', error);
    }
  };

  const sendEmailtoadmin = async () => {
    const apiKey = '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'; // Replace with your actual API key
    const url = 'https://api.brevo.com/v3/smtp/email';
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify({
          sender: {
            name: 'Bamboo Digital',
            email: 'info@bamboodigital.in',
          },
          to: [
            {
              email: "info@bamboodigital.in",
              name: "Bamboo Digital",
            },
          ],
          subject: 'You Have A New Enquiry For Bamboo Digital!',
          htmlContent: `<!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>Thank You for Your Inquiry</title>
              <style>
                  /* Add your email styles here */
                  body {
                      font-family: Arial, sans-serif;
                      background-color: #f0f0f0;
                      margin: 0;
                      padding: 0;
                  }
  
                  .container {
                      max-width: 600px;
                      margin: 0 auto;
                      padding: 20px;
                      background-color: #ffffff;
                      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                      border-radius: 8px;
                  }
  
                  h1 {
                      font-size: 24px;
                      font-weight: bold;
                      margin-bottom: 20px;
                  }
  
                  p {
                      font-size: 16px;
                      line-height: 1.5;
                      margin-bottom: 20px;
                  }
  
                  .contact-info {
                      margin-top: 20px;
                      font-size: 14px;
                  }
              </style>
          </head>
          <body>
              <div class="container">
                  <h1>Hello Bamboo Digital team new ${ticketNumber} ${formData.name},</h1>
                  <p>Email: ${formData.email}</p>
                  <p>Phone: ${formData.phone}</p>
                  <p>${formData.message}</p>
              </div>
          </body>
          </html>
          `,
        }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        // Handle success
        // console.log('Email sent:', responseData);
      } else {
        // Handle error
        // console.error('Error sending email:', responseData);
      }
    } catch (error) {
      // Handle any errors here
      // console.error('Error sending email:', error);
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);

    const db = await getFirestore();
    const docRef = await addDoc(collection(db, "users"), {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message:  formData.message,
      time: new Date().toISOString(),
    });

    // console.log("Document written with ID: ", docRef.id);

    sendEmailtoadmin();
    sendEmail();
    setFormData({
      name: '',
      email: '',
      message: '',
      phone: '',
    });
  };

  return (
    <div className="w-full md:max-w-[460px] md:h-[446px] mx-auto md:mx-0 0 md:mt-0 p-6 bg-white border rounded-lg">
      <h3 className='font-semibold pb-2 text-xl'>
        Reach out
      </h3>
      <p className='body-text md:text-sm text-text-2 pb-3 md:pb-[20px]'>Let's chat about designs, projects, and exciting opportunities!</p>
      <form onSubmit={handleSubmit}>
        <div className=" ">
          <input
            placeholder='Name'
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-4 border rounded-lg focus:outline-none focus:ring focus:ring-primary h-[50px]"
            required
          />
        </div>
        <div className="mt-4">
          <input
            placeholder='Email Address'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-3 py-4 border rounded-lg focus:outline-none focus:ring focus:ring-primary h-[50px]"
            required
          />
        </div>
        <div className="mt-4">
          <input
            placeholder='Phone Number'
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className="w-full px-3 py-4 border rounded-lg focus:outline-none focus:ring focus:ring-primary h-[50px]"
            required
          />
        </div>
        <div className="mt-4">
          <input
            placeholder='Type a message'
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full px-3 py-4   border rounded-lg focus:outline-none focus:ring focus:ring-primary h-[50px]"
            rows="4"
            required
          />
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="px-6 btn py-3 w-full h-14 color-#272E0F font-normal font text-lg rounded-full focus:outline-none focus:ring focus:ring-primary hover:bg-transparent hover:border border-primary"
          >
            Send Message
          </button>
        </div>
      </form>
    </div>
  );
}

export default HeroForm;
