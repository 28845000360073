import React, { useState, useEffect } from 'react';
import PortfolioData from './../components/PortfolioData';
import { Link } from 'react-router-dom';
 

const TechPage = () => {
  const [items, setItems] = useState(PortfolioData);
  // eslint-disable-next-line
  const [selectedCategory, setSelectedCategory] = useState('All');
  // eslint-disable-next-line
  const [uniqueCategories, setUniqueCategories] = useState(new Set());
  // Extract unique categories when the component mounts
  useEffect(() => {
      const categories = new Set();
      items.forEach((elem) => {
          elem.category.forEach((cat) => {
              categories.add(cat);
          });
      });
      setUniqueCategories(categories);
  }, [items]);

  // Function to filter items based on the selected category
  const filterItems = (category) => {
      if (category === 'All') {
          setItems(PortfolioData);
      } else {
          const filteredItems = PortfolioData.filter((elem) =>
              elem.category.includes(category)
          );
          setItems(filteredItems);
      }
      setSelectedCategory(category);
  };

  // Function to handle the select dropdown change
  const handleSelectChange = (event) => {
      const category = event.target.value;
      filterItems(category);
  };
  return (
    <section className='page-width'>
      <div className='page-width'>
      <div class="flex flex-col md:flex-row justify-between gap-5 md:pt-[100px] items-start">
        <h1 class="">The Backbone of Your Digital Presence</h1>
        <p class="body-text md:w-1/2  text-text-3">Our Tech team is here to build, code, and shape your digital presence.
        We create UI UX & websites that are not just visually stunning 
        but also highly functional, ensuring a seamless user experience. 
        Our app development expertise turns your ideas into interactive, user-friendly applications. 
        Your vision, combined with our technical excellence, unlocks endless possibilities. 
        We don't just build; we create the tools  that make your brand stand out digitally.
          </p>
        </div>
        <div className='flex justify-end py-10  gap-4 md:py-20'>
                {/* <label htmlFor="categorySelect">Filter by Service type</label>
                <select className='border-b border-secondary'
                    id="categorySelect"
                    value={selectedCategory}
                    onChange={handleSelectChange}
                >
                    <option value="All">All</option>
                    {[...uniqueCategories].map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select> */}
            </div>

            <div className='flex flex-wrap justify-between gap-8'>
                {items.map((elem) => {
                    const { id, name, url, image, category,pages } = elem;
                    if (pages.includes('tech')) {
                      return (
                            <Link to={url} key={id} >
                            <div className='bg-white border max-w-max rounded-lg'>
                                <img
                                    width={600}
                                    height={320}
                                    className='rounded-t-lg bg-bg-1'
                                    src={image}
                                    alt={name}
                                />
                                <div className=' p-4 md:p-10 flex justify-between items-center'>
                                    <h2 className='font-normal text-base md:text-3xl'>{name}</h2>
                                    <div className='flex gap-[0.625rem]'>
                                        {category.map((cat, index) => (
                                            <a key={index} className=' p-1 md:p-3 border text-xs border-black rounded-full'>
                                                {cat}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Link>
                          );
                    }
                })}
            </div>
      </div>
    </section>
  )
}

export default TechPage