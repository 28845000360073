import React, {useRef, useState, useEffect } from 'react';
import SerCard from './SerCard';
import com from "./../assets/Frame 1 (4).png";


import prod from './../assets/Frame 1 (3).png'
import media from './../assets/Frame 1 (2).png'
import tech from './../assets/Frame 1 (1).png'

const serviceData = [
  {
    url: com,
    title: `Specialized Communication and Strategy`,
    des: `Creative individuals committed to cultivating a positive brand image for businesses via communication strategies. We employ a developed roadmap to facilitate your business growth.`,
    id: "s1",
    scrollX: 50,
    linkTo: "/services/communication",
    cate: "Communications"
  },
  {
    url: prod,
    title: `Inspiring & Provoking Imagery`,
    des: `Our crew provides comprehensive end-to-end production services, guiding you through every stage to realize your brand’s vision. From concept development to final delivery, we ensure exceptional quality and efficiency, creating impactful content that captivates your audience.`,
    id: "s2",
    scrollX: 450,
    linkTo: "/services/production",
    cate: "Productions"
  },
  {
    url: media,
    title: `Data-driven Analysis`,
    des: `A team of analysts empowers businesses to achieve exceptional growth through data-driven strategies and result-oriented campaigns. Our expertise maximizes your ROI, driving measurable results and expanding your digital presence.`,
    id: "s3",
    scrollX: 800,
    linkTo: "/services/media-labs",
    cate: "Media Labs"
  },
  {
    url: tech,
    title: `UI/UX Design and Web Development`,
    des: `We offer exceptional UI/UX design and web development services, combining creativity with advanced technologies to deliver digital experiences. We transform concepts into functional, engaging digital solutions.`,
    id: "s4",
    scrollX: 1000,
    linkTo: "/services/tech",
    cate: "Tech"
  },
];

function StickyComponent() {
  const [persentage, setPercentage] = useState(0);
  const elementRef = useRef(null);
  const scrollSectionRef = useRef(null);
  
  const addSxrolX = (elementId,offset)=>{
    const oldClass = document.querySelectorAll('.newClassTobeAdd') 
    oldClass.forEach((element) => {
      element.classList.remove('targettedClass');
    });
    if(offset) {
      window.scrollTo(0, offset);
    }
    const newElementId = document.getElementById(elementId) 
    newElementId.classList.add('targettedClass')
  }
  // Attach the scroll event listener when the component mounts
 
  useEffect(() => {
    const element = elementRef.current;
    // const scrollSection = scrollSectionRef.current;

    const handleScroll = () => {
      const offsetTop = element.parentElement.offsetTop;
      let newPercentage = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
      newPercentage = newPercentage < 0 ? 0 : newPercentage > 105 ? 105 : newPercentage
      setPercentage(newPercentage);
    };

    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    const scrollSection = scrollSectionRef.current;
    scrollSection.style.transform = `translate3d(${-persentage}vw, 0, 0)`;
    const oldClass = document.querySelectorAll('.newClassTobeAdd') 
      oldClass.forEach((element) => {
        element.classList.remove('targettedClass');
      });
    if (persentage >= 0 && persentage <= 10) {
    let newElementId = document.getElementById('cards1') 
    newElementId.classList.add('targettedClass')
    } else if (persentage >= 11 && persentage <=60 ) {
    let newElementId = document.getElementById('cards2') 
    newElementId.classList.add('targettedClass')
    } else if (persentage >= 61 && persentage <= 80) {
    let newElementId = document.getElementById('cards3') 
    newElementId.classList.add('targettedClass')
    } else if (persentage > 80){
      let newElementId = document.getElementById('cards4') 
      newElementId.classList.add('targettedClass')
    }
  }, [persentage]);


  return (
    <div ref={elementRef} className={`sticky-component sticky_parent`}>
      <div className='page-width sticky'>
        <div className='py-10' style={{paddingLeft:"7rem"}}>
          <ul className='list-none flex gap-2'>
            {serviceData.map((data, index) => (
              <li key={index}>
                <a  id={`card${data.id}`} className='botom-line newClassTobeAdd' onClick={()=>addSxrolX(`card${data.id}`,data.scrollX)} href={`#card${data.id}`}>{data.cate}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className='relative'>
          <ul ref={scrollSectionRef} style={{paddingLeft:"7rem"}} className={`bg-white flex list-none absolute scroll_section`} >
          {serviceData.map((data) => (
            <SerCard linkTo={data.linkTo} id={data.id} img={data.url} heading={data.title} para={data.des}/>
            ))}
            </ul>
        </div>
      </div>
    </div>
  );
}

export default StickyComponent;


// <div ref={myRef} className={`sticky-component ${isSticky ? 'sticky' : 'inherit'}`}>
// <div className='page-width'>
//   <div className='py-10'>
//     <ul className='list-none flex gap-2'>
//       {serviceData.map((data, index) => (
//         <li key={index}>
//           <a  id={`card${data.id}`} className='botom-line newClassTobeAdd' onClick={()=>addSxrolX(`card${data.id}`,data.scrollX)} href={`#card${data.id}`}>{data.cate}</a>
//         </li>
//       ))}
//     </ul>
//   </div>
//   <div className='relative'>
//     <ul className={`bg-white flex list-none ${isSticky ? 'absolute left-0' : 'inherit'}`} style={{ left: `${leftPosition}%` }}>
//     {serviceData.map((data) => (
//       <SerCard linkTo={data.linkTo} id={data.id} img={data.url} heading={data.title} para={data.des}/>
//       ))}
//       </ul>
//   </div>
// </div>
// </div>