import React, { useState, useEffect } from 'react'
import image1 from "./../assets/Copy of bamboo digital (1) (1) 1.svg"
import {
  Link
} from "react-router-dom";
import serviceFile from "./../components/PortfolioData"


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line
  const [items, setItems] = useState(serviceFile);
  const [uniqueCategories, setUniqueCategories] = useState(["communications"]);
  const toggleOpen = () => setIsOpen(!isOpen);
  const closeMobileMenu = () => setIsOpen(false);
  const pages = [{text:"Communication",path:"/services/communication",category:"communications"},{text:"Production",path:"/services/production",category:"production"},{ text:'Tech',path:"/services/tech",category:"medLaB"},{ text:"Media Labs",path:"/services/media-labs",category:"tech"}]
  // Extract unique categories when the component mounts
  useEffect(() => {
    const categories = new Set();
    items.forEach((elem) => {
      elem.category.forEach((cat) => {
        categories.add(cat);
      });
    });
    setUniqueCategories('communications');
  }, [items]);

  useEffect(() => {
    const navbarMenuLi = document.querySelector('.navbar-menu-li');
    const arrow = navbarMenuLi.querySelector('.arrow');

    navbarMenuLi.addEventListener('mouseover', function () {
      arrow.style.transform = 'rotate(180deg)';
      arrow.style.transition = 'transform 0.5s';
    });

    navbarMenuLi.addEventListener('mouseout', function () {
      arrow.style.transform = 'rotate(0deg)'; // Reset the rotation when the mouse leaves.
      arrow.style.transition = 'transform 0.5s';
    });
  }, [])

  const itemsToRender = [];
let count = 0; // Initialize a counter

items.forEach((elem, index) => {
  const { id, name, url, image, pages } = elem;
  
  if (count < 2 && pages.includes(uniqueCategories)) {
    itemsToRender.push(
      <Link to={url} key={id}>
        <div className='bg-white border max-w-sm rounded-lg'>
          <img
            width={600}
            height={320}
            className='rounded-t-lg bg-bg-1'
            src={image}
            alt={name}
          />
          <div className=' p-3 md:p-6 flex justify-between items-center'>
            <h2 className='text-lg font-medium md:text-lg'>{name}</h2>
          </div>
        </div>
      </Link>
    );
    
    count++; // Increment the counter
  }
});

const hoverElementToShow = (event, element) => {
  if (event.target.classList.contains('navbar-sub-menu-a')) {
    const currentElements = document.querySelectorAll('.navbar-sub-menu-a');
    currentElements.forEach((e) => {
      e.classList.remove('opacity-100'); // Remove the class from all elements
      e.classList.add('opacity-50'); // Add the class 'opacity-50' to all elements
    });
    event.target.classList.remove('opacity-50'); // Remove 'opacity-50' from the current element
    event.target.classList.add('opacity-100'); // Add 'opacity-100' to the current element
    setUniqueCategories(element);
  }
}

  return (
    <div className='border-b fixed w-full z-50 bg-white '>
      <nav className="page-width flex items-center py-5 justify-between navbar">

        <div className="flex items-center space-x-4">
          <div className="text-4xl font-bold">
            <Link to='/'>
              <img className=' max-w-[91px] ' alt='image1' src={image1} />
            </Link>
          </div>
          <div onClick={toggleOpen} className="cursor-pointer md:hidden">
            <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
        </div>
        <ul className={`space-x-4 ${isOpen ? 'flex' : 'hidden'} md:flex items-center navbar -menu`}>
          <li>
            <Link className="text-sm font-medium text-text-1 hover:text-primary px-5 py-[14px] rounded-full " to="/about">About</Link>
          </li>
          <li className='navbar-menu-li px-5 py-[14px] navbar-sub-menu-new'>
            <Link to="/services" className="text-sm font-medium  text-text-1 hover:text-primary rounded-full ">Services</Link>
            <span className="arrow absolute mt-1 mx-1" style={{marginTop:"5px"}}><svg className='w-4 h-4' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg></span>
            <ul className="navbar-sub-menu w-full rounded">
              <li className="navbar-sub-menu-li hoverTemplateUiLi page-width">
                <div className='inline'>
                  {pages.map((element, index) => {
                    return <div className={` navbar-sub-menu-a${index}  `} ><Link to={element.path}><h2 onMouseMove={(e)=>{hoverElementToShow(e,element.category)}} className='text-2xl font-medium navbar-sub-menu-a pt-5 opacity-50'>{element.text}</h2></Link></div>
                  })}
                </div>
                <ul className="navbar-sub-menu-nest w-auto justify-around">
                  {itemsToRender}
                </ul>
              </li>
            </ul>
          </li>
          <li className="relative group">
            <Link to="/works" className="text-sm font-medium text-text-1 hover:text-primary px-5 py-[14px] rounded-full flex items-center gap-1 "><span>
              Works
            </span>
            </Link>
          </li>
          <li>
            <Link to="/carrers" className="text-sm font-medium text-text-1 hover:text-primary px-5 py-[14px] rounded-full ">Careers</Link>
          </li>
          <li>
            <Link to="/contact" className="text-sm font-medium text-text-1 hover:text-primary px-5 py-[14px] rounded-full flex items-center gap-1 border-2 "><span>Contact</span> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.16602 10H15.8327M15.8327 10L10.8327 5M15.8327 10L10.8327 15" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            </Link>
          </li>
          <li className='flex'>
            <Link to="https://wa.link/voikzj" className="text-sm font-medium text-text-1 hover:text-primary rounded-full ">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="24" fill="#BEDA2F" />
                <path d="M31.5034 24C31.5034 28.1421 28.1456 31.5 24.0034 31.5C22.3064 31.5 16.504 31.5 16.504 31.5C16.504 31.5 17.8033 28.3801 17.2833 27.334C16.7841 26.3297 16.5034 25.1976 16.5034 24C16.5034 19.8579 19.8613 16.5 24.0034 16.5C28.1456 16.5 31.5034 19.8579 31.5034 24Z" stroke="#272E0F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </Link>
          </li>
        </ul>
        <div className="relative inset-0 md:inset-auto md:hidden gap-5 flex items-center justify-center">
          <Link to="https://wa.link/voikzj" className="text-sm font-medium text-text-1 hover:text-primary rounded-full ">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="24" fill="#BEDA2F" />
              <path d="M31.5034 24C31.5034 28.1421 28.1456 31.5 24.0034 31.5C22.3064 31.5 16.504 31.5 16.504 31.5C16.504 31.5 17.8033 28.3801 17.2833 27.334C16.7841 26.3297 16.5034 25.1976 16.5034 24C16.5034 19.8579 19.8613 16.5 24.0034 16.5C28.1456 16.5 31.5034 19.8579 31.5034 24Z" stroke="#272E0F" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </Link>
          <div className="text-sm font-medium text-text-1 hover:text-primary rounded-full  "><button className='' >

            <div className="text-sm font-medium text-text-1 hover:text-primary rounded-full">
              <button className='' onClick={toggleOpen}>
                {isOpen ? 'Close' : 'Menu'}
              </button>
            </div>
          </button></div>




          {isOpen && (

            <div className='md:hidden fixed top-0 left-0 w-screen h-screen bg-white' style={{ zIndex: 2 }}>
              <div className='page-width pt-14'>
                <div className='flex flex-col justify-between gap-24 '>
                  <div className='flex items-center justify-between' >
                    <Link to='/'>
                      <img className=' max-w-[91px] ' alt={image1} src={image1} />
                    </Link>
                    <button onClick={closeMobileMenu} className=" flex items-center justify-end w-full m-auto text-text-1 hover:text-primary">
                      <p className='tex-right'>Close</p>
                    </button>
                  </div>
                  <ul className="flex flex-col space-y-2 items-center">
                    <li className='w-full' >
                      <Link onClick={closeMobileMenu} to="/about" className="text-lg text-text-1 hover:text-primary  rounded-full flex items-center justify-between ">
                        <span>About</span>
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#98A2B3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </span>
                      </Link>
                      <hr className='w-full h-[1px] my-6 bg-[#EDEFF1]' />
                    </li>
                    <li className='w-full'>
                      <Link onClick={closeMobileMenu} to="/services" className="text-lg text-text-1 hover:text-primary  rounded-full flex items-center justify-between "><span>Services</span>
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#98A2B3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </span></Link>
                      <hr className='w-full h-[1px] my-6 bg-[#EDEFF1]' />
                    </li>
                    <li className='w-full'>
                      <Link onClick={closeMobileMenu} to="/works" className="text-lg text-text-1 hover:text-primary  rounded-full flex items-center justify-between "><span>
                        Works
                      </span>
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#98A2B3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>

                        </span>
                      </Link>
                      <hr className='w-full h-[1px] my-6 bg-[#EDEFF1]' />
                    </li>
                    <li className='w-full'>
                      <Link onClick={closeMobileMenu} to="/carrers" className="text-lg text-text-1 hover:text-primary  rounded-full flex items-center justify-between "><span>
                        Careers
                      </span>

                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#98A2B3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span>
                      </Link>
                      <hr className='w-full h-[1px] my-6 bg-[#EDEFF1]' />
                    </li>
                    <li className='w-full'>
                      <Link onClick={closeMobileMenu} to="/contact" className="text-lg text-text-1 hover:text-primary  rounded-full flex items-center justify-between "><span>
                        Contact

                      </span>
                        <span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#98A2B3" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </span>
                      </Link>
                    </li>
                  </ul>
                  <div>
                    <p className=' text-text-3 text-xs text-center '>
                      Copyright &copy; {new Date().getFullYear()} Bamboo Digital
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>

      </nav>
    </div>

  )
}

export default Navbar

