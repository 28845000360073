import React, { useState, useEffect } from 'react';
import PortfolioData from './../components/PortfolioData';
import { Link } from 'react-router-dom';
 
const WorksCard = () => {
    const [items, setItems] = useState(PortfolioData);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [uniqueCategories, setUniqueCategories] = useState(new Set());

    // Extract unique categories when the component mounts
    useEffect(() => {
        const categories = new Set();
        items.forEach((elem) => {
            elem.category.forEach((cat) => {
                categories.add(cat);
            });
        });
        setUniqueCategories(categories);
    }, [items]);

    // Function to filter items based on the selected category
    const filterItems = (category) => {
        if (category === 'All') {
            setItems(PortfolioData);
        } else {
            const filteredItems = PortfolioData.filter((elem) =>
                elem.category.includes(category)
            );
            setItems(filteredItems);
        }
        setSelectedCategory(category);
    };

    // Function to handle the select dropdown change
    const handleSelectChange = (event) => {
        const category = event.target.value;
        filterItems(category);
    };

    return (
        <div className='page-width'>

            <div className='flex justify-between flex-wrap gap-5 md:pt-[100px] items-start'>
                <h1 className=' '>
                    Our Featured Works
                </h1>
                <p className='body-text text-text-3'>
                    Discover a curated collection showcasing our remarkable projects that exemplify<br/><strong className='text-text-2' >expertise</strong>, <strong className='text-text-2' >creativity</strong>, and <strong className='text-text-2' >dedication to excellence</strong>. Explore to appreciate our<br/>transformative capabilities.
                </p>
            </div>
            <div className='flex justify-end py-10  gap-4 md:py-20'>
                <label htmlFor="categorySelect">Filter by Service type</label>
                <select className='border-b border-secondary' style={{background:"#fcfcfc"}}
                    id="categorySelect"
                    value={selectedCategory}
                    onChange={handleSelectChange}
                >
                    <option value="All">All</option>
                    {[...uniqueCategories].map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
            </div>

            <div className='flex flex-wrap justify-between gap-8'>
                {items.map((elem) => {
                    const { id, name, url, image, category } = elem;
                    return (
                        <Link to={url} key={id} >
                            <div className='bg-white border max-w-max rounded-lg'>
                                <img
                                    width={600}
                                    height={320}
                                    className='rounded-t-lg bg-bg-1'
                                    src={image}
                                    alt={name}
                                />
                                <div className=' p-4 md:p-10 flex justify-between items-center'>
                                    <h2 className='font-normal text-base md:text-3xl'>{name}</h2>
                                    <div className='flex gap-[0.625rem]'>
                                        {category.map((cat, index) => (
                                            <a
                                                key={index}
                                                className=' p-1 md:p-3 border text-xs border-black rounded-full'
                                            >
                                                {cat}
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default WorksCard;
