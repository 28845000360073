import React, { useState } from 'react';

const jobDatas = [
  {
    title: "Graphic Designer",
    description: "The focus of this role is to conceptualise creative designs for a wide array of media to fit the needs of internal teams and external clients. You will oversee the design's production to ensure the designs are completed in a timely manner. You will also use your knowledge of design software to accomplish these responsibilities.",
    category: "Design",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },

  {
    title: "Video Editor",
    description: "Potential Candidate will be in charge of merging recorded footage, applying creative and technical abilities, and creating the final output to comply with the purpose, tone, and visual style as a Video Editor. You will also work collaboratively with producers, directors, and other team members to bring the idea to life with seamless and visually appealing edits. ",
    category: "Design",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },
  {
    title: "Artist Management Intern",
    description: "We're seeking a motivated and passionate Artist Management Intern to join our team. This internship offers a unique opportunity to gain hands-on experience and insights into the intricacies of artist management. As an Artist Management Intern, you will play a pivotal role in supporting the team and contribute to the success of our artists' careers. This role is perfect for passionate individuals eager to learn and grow in a fast-paced, creative environment. ",
    category: "Management",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },
  {
    title: "Data Engineer",
    description: "As a Data Engineer, you'll be instrumental in building and maintaining the infrastructure for collecting, storing, and analyzing data and Design and implement efficient data pipelines to move, transform, and load data from diverse sources into our systems. you'll be a key contributor to our data-driven decision-making process, ensuring that data is available, reliable, and accessible for analysis and insights. ",
    category: "Tech",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },
  {
    title: "Machine Learning Engineer  (NLP Specialist) ",
    description: "As a Machine Learning Engineer, your primary responsibility is to design, develop, and deploy machine learning models that drive data-driven solutions and innovations. you'll be at the forefront of turning data into actionable insights, building and deploying machine learning solutions that drive business value and innovation.",
    category: "Tech",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },
  {
    title: "Full stack developer - App & SaaS experience",
    description: "As a Full Stack Developer with experience in both application and Software as a Service (SaaS) development, you will be responsible for designing, building, and maintaining versatile web and mobile applications. You will contribute to the development of innovative web and mobile applications as well as SaaS solutions, delivering high-quality software that meets user needs and business objectives.    ",
    category: "Tech",
    url: "https://docs.google.com/forms/d/e/1FAIpQLSe_5uBiV3PIH8c7GOF3-bGqyyhxida8iz5HIE6rksitQbTftg/viewform",
  },
  // Add more job listings
];const Joblisting = () => {
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default category
  const [showCategories, setShowCategories] = useState(false);

  const handleReadMoreClick = (jobTitle) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [jobTitle]: true,
    }));
  };

  // Extract unique job categories from the job data
  const jobCategories = Array.from(new Set(jobDatas.map((job) => job.category)));

  // Filter job listings based on the selected category
  const filteredJobs = selectedCategory === 'All'
    ? jobDatas
    : jobDatas.filter((job) => job.category === selectedCategory);

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  return (
    <div className='page-width'>
      <div className="category-toggle flex gap-2 items-center">
        <button
          className={`px-4 py-2 border rounded-full ${
            selectedCategory === 'All' ? 'bg-secondary text-white' : 'hover:bg-secondary hover:text-white'
          }`}
          onClick={() => setSelectedCategory('All')}
        >
          All
        </button>
        {jobCategories.map((category, index) => (
          <button
            key={index}
            className={`px-4 py-2 border rounded-full ${
              selectedCategory === category ? 'bg-secondary text-white' : 'hover:bg-secondary hover:text-white'
            }`}
            onClick={() => setSelectedCategory(category)}
          >
            {category}
          </button>
        ))}
      </div>
      <ul className="job-list">
        {filteredJobs.map((job, index) => (
          <li key={index} className='flex items-start justify-between pt-10  border-t mt-10'>
            <div className='w-2/3'>
              <h2 className='font-normal pb-4'>{job.title}</h2>
              <p className=' text-text-3'>{job.description}</p>
            </div>
            <a className='text-2xl font-medium flex items-center' href={job.url} target="_blank" rel="noopener noreferrer"><span>Apply Now</span> <span>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.33594 22.667L22.6693 9.33366M22.6693 9.33366V22.667M22.6693 9.33366H9.33594" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 </span></a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Joblisting;