import ap from "./../assets/Asian Paints.webp"
import cs from "./../assets/Casagrand.webp"
import gs from "./../assets/Gsquare.webp"
import skift from "./../assets/skift.webp"
import petitfée from "./../assets/petitfee.webp"
import Shilputsi from "./../assets/Shilputsi.webp"
import htlw from "./../assets/Homes to life.webp"
import ls from "./../assets/lodha.webp"
import htli from "./../assets/instagram HTL.webp"
import htly from "./../assets/Youtube HTL.webp"
import Kaya from "./../assets/Kaya.webp"
import love from "./../assets/Lovechild.webp"
import Podar from "./../assets/Podar.webp"
import Jio from "./../assets/Jio mart.webp"
import WorkDetail from "./WorkDetail"
import skiftimg1 from './../assets/Main-Banner_1.jpg'
import skiftimg2 from './../assets/Main-Banner_2.jpg'
import skiftimg3 from './../assets/Small-Banner_1.jpg'
import skiftimg4 from './../assets/Small-Banner_2.jpg'
import petife1 from './../assets/Petitfee/Main-Banner.jpg'
import petife2 from './../assets/Petitfee/Main-Banne2.jpg'
import htl1 from './../assets/HomesToLife/Main-Banner_1.jpg'
import htl2 from './../assets/HomesToLife/Main-Banner_2.jpg'
import htl3 from './../assets/HomesToLife/Small-Banner_1.jpg'
import htl4 from './../assets/HomesToLife/Small-Banner_2.jpg'
import shil1 from './../assets/Shilputsi/Main-Banner.jpg'
import lodha1 from './../assets/Lodha/Main-Banner.jpg'
import kaya1 from './../assets/Kaya/Main-Banner.jpg'
import asian1 from './../assets/Asian Paints/Main-Banner.jpg'
import asian2 from './../assets/Asian Paints/Main-Banner2.jpg'
import gsquare1 from './../assets/Gsquare/Main-Banner.jpg'
import love1 from './../assets/Love Child/Main-Banner.jpg'
import poddar1 from './../assets/Podar/Main-Banner.jpg'

const PortfolioData = [
  {
    "id": 1,
    "pages": ["communications","production","medLaB","tech"],
    "name": "Skift",
    "url": "/works/skift",
    "image": skift, // Assuming "skift" is a variable that contains the image URL
    "category": ["Product Packaging", "Branding"],
    "heading": "Skin + Fitness = Skift",
    "img1": skiftimg1,
    "service": ["Visual Elements - UI/UX Strategy", "Brand Tonality - Communication Strategy", 'Tech', 'Packaging', "Brand Production - Brand Awareness Strategy", 'Email marketing', 'Content Strategy'],
    "brandName": "Skift",
    "para": ["Skift caters to a bigger audience, athletes and fitness enthusiasts, and empowers them to pursue their fitness goals while nurturing their skin with the care it deserves with skincare and haircare products. Skift harnesses science and nature to create skincare products that enhance skincare during your athletic performance and leave your skin feeling refreshed and ready for more.", "Our collaboration with Skift began with the goal of launching their brand and transforming how people view sweat, encouraging them to embrace their natural skin and unlock their full potential."],
    "sector": "Skin & Fitness",
    "img2": skiftimg2,
    "img3": skiftimg3,
    "img4": skiftimg4
  },
  {
    "id": 2,
    "pages": ["communications","production","tech"],
    "name": "Petitfée",
    "url": "/works/petitfee",
    "image": petitfée,
    "category": ["Photoshoot", "Branding"],
    "heading": "Petitfee",
    "img1": petife1,
    "service": ["Performance Marketing", "Brand Communications", 'Graphic Designing', 'UI/UX'],
    "brandName": "Petitfee",
    "para": ["Petitfe­e, a well-known Korean skincare­ brand, embodies the perfect blend of traditional wisdom and modern scientific advancements in skincare. Combining nature and innovation, the brand offers various skincare solutions addressing different skin types and concerns.",
    'While exiting the Indian market, Petitfee faced the challenge of selling off inve­ntory. This required careful planning and strate­gizing to efficiently liquidate their stock through clearance sales and promotions.'
  ],
    "sector": "Skin Care",
    "img2": petife2,
    "img3": "",
    "img4": ""
  },
  {
    "id": 3,
    "pages": ["communications","medLaB"],
    "name": "Shilputsi",
    "url": "/works/shilputsi",
    "image": Shilputsi,
    "category": ["Resposive Web Design", "Branding"],
    "heading": "Shilputsi",
    "img1": shil1,
    "service": ["web redesign", "UI UX",'SEO optimization'],
    "brandName": "Shilputsi",
    "para": ["Shilputsi, established 45 years ago, is now a globalized HR solution to navigate culture-specific challenges in India. Today, that transformative legacy continues to thrive. ",'Shilputsi has revolutionized HR solutions with a unique perspective that blends cultural sensitivity, innovation, and strategic insight. It has ushered in a new era of cutting-edge HR solutions for their esteemed clients. Shilputsi cherishes the trust their clients have in them and takes pride in being their dependable partners.'],
    "sector": "Software Company",
    "img2": "",
    "img3": "",
    "img4": ""
  },
  {
    "id": 4,
    "pages": ["communications","production","tech"],
    "name": "HomesToLife",
    "url": "/works/homes-to-life",
    "image": htlw,
    "category": ["Resposive Web Design", "Branding"],
    "heading": "HomesToLife",
    "img1": htl1,
    "service": ["Brand Tonality - Communication Strategy", "Visual Elements - UI/UX Strategy",'Website Revamping - Tech', "Brand Production - Brand Awareness Strategy","Lead Generation - Performance Strategy"],
    "brandName": "HomesToLife",
    "para": ["HomesToLife, a Singaporean premium furniture company, needed an extensive cross-border expansion strategy to focus on establishing a significant presence in the Indian furniture market.",
  'HomesToLife’s USPs of 400+ colours, 17+ leathers & fabrics, and 30 configurations',
  'With over 45 years of experience, HomesToLife is known for its elegant & classic sofa collections. HomesToLife aimed to establish a strong presence, bridge cultural gaps, and become the top choice among the Indian population for premium furniture.',
  'Bamboo Digital embraced this challenge by setting clear objectives by achieving nationwide recognition for HomesToLife, redefining its brand persona, identifying its niche, creating brand awareness, and understanding the preferences of Indian consumers by closely working on'
],
    "sector": "Home Decor",
    "img2": htl2,
    "img3": htl3,
    "img4": htl4
  },
  {
    "id": 5,
    "pages": ["communications"],
    "name": "Casagrand",
    "url": "/works/casagrand",
    "image": cs,
    "category": ["Informative Blog"],
    "heading": "Casagrand",
    "img1": cs,
    "service": ["Informative Blog"],
    "brandName": "Casagrand",
    "para": ["Casagrand is a well-established and reputable real estate developer based in India. Known for its commitment to quality and innovation, Casagrand has earned a strong presence in the real estate industry. The company specializes in creating residential properties that offer its customers contemporary design, modern amenities, and a high standard of living.",
    "We wrote an informative blog that ranks #1 on SERPs. We strategically curated valuable content, fine-tuned keywords, and implemented successful SEO strategies that propelled it to the forefront. Our dedication to delivering authoritative information underscored the blog's outstanding relevance and visibility in the online landscape."
  ],
    "sector": "Real Estate",
    "img2": "",
    "img3": "",
    "img4": ""


  },
  {
    "id": 6,
    "pages": ["tech"],
    "name": "Lodha Sterling",
    "url": "/works/lodha-sterling",
    "image": ls,
    "category": ["Mobile Interface Design"],
    "heading": "Lodha Sterling",
    "img1": ls,
    "service": ["Mobile Interface Design"],
    "brandName": "Lodha",
    "para": ["Lodha Group has been India's leading real estate developer for the last 3 decades. Headquartered in Mumbai, Lodha Group has earned a reputation for its commitment to creating exceptional living spaces and transforming urban landscapes. With a strong focus on design, innovation, and sustainability, Lodha has delivered numerous iconic residential and commercial projects across India. Their dedication to quality craftsmanship and customer satisfaction has positioned them as a prominent player in the real estate industry, known for shaping modern living and redefining luxury in India.",
    "We remodelled a swift mobile interface for India's leading real estate developer. A game-changer UI UX with a user-centric & highly functional mobile-responsive website where every service and facility is at your fingertips and is just a click away. We redefined how you explore & invest in your dream properties. Aligning seamlessly with the esteemed real estate developer's vision and goals."
    ,"User-centric website is one of the cornerstones of a brand’s success. So, we built a user-centric website with a mobile-friendly design, premium UI/UX, smart widgets, and a faster-loading website, ultimately leading to more engagement and website traffic. Now, you can effortlessly navigate through a world of real estate possibilities. It's all at your fingertips."
  ],
    "sector": "Real Estate",
    "img2": lodha1,
    "img3": "",
    "img4": ""

  },
  // {
  //   "id": 7,
  // "pages": [""],
  //   "name": "Homes To Life Instagram",
  //   "url": "/works/homes-to-life-instagram",
  //   "image": htli,
  //   "category": ["Ad Campaign"],
  //   "heading": "Sample Heading",
  //   "img1": htli,
  //   "service": ["Service 1", "Service 2"],
  //   "brandName": "Sample Brand",
  //   "para": ["Lorem ipsum dolor sit amet, consectetur adipiscing elit.",],
  //   "sector": "Sample Sector",
  //   "img2": "https://picsum.photos/200/300",
  //   "img3": "https://picsum.photos/200/300",
  //   "img4": "https://picsum.photos/200/300"

  // },
  // {
  //   "id": 14,
  // "pages": [""],
  //   "name": "Homes To Life Youtube",
  //   "url": "/works/",
  //   "image": htly,
  //   "category": ["Campaign"],
  //   "heading": "Sample Heading",
  //   "img1": "https://picsum.photos/200/300",
  //   "service": ["Service 1", "Service 2"],
  //   "brandName": "Sample Brand",
  //   "para": ["Lorem ipsum dolor sit amet, consectetur adipiscing elit.",],
  //   "sector": "Sample Sector",
  //   "img2": "https://picsum.photos/200/300",
  //   "img3": "https://picsum.photos/200/300",
  //   "img4": "https://picsum.photos/200/300"

  // },
  {
    "id": 8,
    "pages": ["communications","production","medLaB"],
    "name": "Kaya Performance Marketing",
    "url": "/works/kaya",
    "image": Kaya,
    "category": ["Campaign"],
    "heading": "Kaya Performance Marketing",
    "img1": Kaya,
    "service": ["Brand Communications", "SEO Strategy"],
    "brandName": "Kaya",
    "para": ["With an increase in the need for skincare and haircare since the early 2000s, Kaya Ltd started its journey on 27 March 2003. Today, they cater to skin and hair care needs with a range of fully equipped clinics & products across India and the Middle East.",
    'Kaya’s expertise offers dermatologically approved and scientifically advanced solutions to enhance the healthy radiance of your skin and hair.'
    ,"We created an awareness ad campaign, ‘Brides of Kaya’.",
    "We assisted Kaya in curating a #BridesofKaya campaign across verticals, showcasing the skincare journey of brides using Kaya’s bridal services.",
    "Aligning Brand Tonality As Per SEO Standards"
  ],
    "sector": "Skin Care",
    "img2": kaya1,
    "img3": "",
    "img4": ""

  },
  {
    "id": 9,
    "pages": [""],
    "name": "Love Child",
    "url": "/works/love-child",
    "image": love,
    "category": ["SEO Optimized Web Design"],
    "heading": "Sample Heading",
    "img1": love1,
    "service": ["SEO Optimized Web Design"],
    "brandName": "Love Child",
    "para": ["LoveChild is an honest, embracive, and playfully vibrant beauty brand, made with empathy at its core. The brand offers an extensive range of high‑performing products for all ages, skin colours & cultures. Enabling a unique expression of self-love. LoveChild celebrates YOU!","With our expertise in SEO strategy and content marketing, we wrote SEO-optimized web content that proudly holds the #5 spot on SERP, showcasing the playful spirit of the products of Lovechild."],
    "sector": "Skin",
    "img2": "",
    "img3": "",
    "img4": ""

  },
  {
    "id": 10,
    "pages": ["communications"],
    "name": "Podar",
    "url": "/works/podar",
    "image": Podar,
    "category": ["SEO Optimized Blog"],
    "heading": "Podar",
    "img1": poddar1,
    "service": ["SEO-optimized blog",],
    "brandName": "Podar",
    "para": ["Podar is a prominent name in the field of education in India. Founded in 1927, the Podar Group of Schools has a rich legacy of providing quality education and nurturing young minds. With a commitment to holistic development, the institution has grown to encompass a wide network of schools and educational initiatives, focusing on academic excellence and character building.","Our team has created an SEO-optimized blog for the prestigious Podar Education Network, a leading player in the Indian education sector. Our blog for them achieved an impressive #3 ranking on the Search Engine Results Page (SERP)."],
    "sector": "Education",
    "img2": "",
    "img3": "",
    "img4": ""
  },
  {
    "id": 11,
    "pages": ["communications"],
    "name": "G Square",
    "url": "/works/gsquare",
    "image": gs,
    "category": ["SEO Optimized Blog"],
    "heading": "G Square",
    "img1": gsquare1,
    "service": ["SEO Optimized Blog"],
    "brandName": "G Square",
    "para": ["G Square is touted to be South India’s Largest and Tamil Nadu’s No. 1 Real Estate Developer. As Tamil Nadu’s No.1 Real Estate Developer, G Square understands that the prime real estate of Tamil Nadu is situated in varied districts and metros. G Square brings to you a lifestyle-defining curation of upscale selections to choose from.",
  "Be it plots for sale in Chennai, Bengaluru, Hyderabad, Coimbatore, Thirupathur, Ambur, Udumalpet, Dindigul, Trichy, Hosur, Mysuru & Ballari, G Square’s projects and properties promise the upgrade for your next chapter in life. Over 10000+ happy customers have trusted the transparent and hassle-free land ownership with G Square.","Bamboo Digital’s expertise in generating SEO web content, blogs, and FAQ sections has been demonstrated through our collaboration with Gsquare. We have created informative and engaging content that connects with their audience and consistently achieved high visibility in Google rankings.  Our content for Gsquare enhanced their online visibility, offering a comprehensive and user-friendly experience for their website visitors. "],
    "sector": "Real Estate",
    "img2": [""],
    "img3": "",
    "img4": ""

  },
  {
    "id": 12,
    "pages": ["communications","medLaB"],
    "name": "Asian Paints",
    "url": "/works/asian-paints",
    "image": ap,
    "category": ["SEO Optimized Blog"],
    "heading": "Asian Paints",
    "img1": asian1,
    "service": ["Email Marketing", "Blogs"],
    "brandName": "Asian Paints",
    "para": ["Home is where we write the story of our lives. Asian Paints is a one-stop solution for all quality products and services across multiple decor and wall paint services.",
    "AP Beautiful Homes Stores and Safe Paint Services help you design your homes with warmth and grandeur. From sourcing quality products across multiple decor categories to evaluating the site and offering 3D visualization, experts from Asian Paints design the home of your dreams just how you like it. The AP team comprises a multi-disciplinary team of designers dedicated to the highest level of detail and creative expression.",

  ],
    "sector": "Paints",
    "img2": asian2,
    "img3": "",
    "img4": ""

  },
  {
    "id": 13,
    "pages": ["communications"],
    "name": "Jio Mart",
    "url": "/works/jio-mart",
    "image": Jio,
    "category": ["Pre-buzz email campaign"],
    "heading": "JioMart",
    "img1": Jio,
    "service": ["Pre-buzz email campaign"],
    "brandName": "Jio",
    "para": ["JioMart is an e-commerce platform in India operated by Reliance Retail, a subsidiary of Reliance Industries. Launched in 2020, it offers various products, including groceries, household items, electronics, and more, focusing on connecting local Kirana stores to customers. JioMart aims to provide a convenient and efficient online shopping experience, leveraging the vast reach of Jio's telecommunications network.","We launched an early email campaign to announce their upcoming monthly offers and deals. The campaign showcased captivating graphics, creating excitement and building anticipation among our audience."],
    "sector": "Ecommerce Retails",
    "img2": "",
    "img3": "",
    "img4": ""

  }
]

export default PortfolioData;

