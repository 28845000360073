import React from 'react'

const Terms = () => {
  React.useEffect(() => {
    document.title = 'Terms of service - Bamboo Digital';
  }, []);
  return (
    <div className=' pt-10  md:pt-[100px]'>
      <section className='page-width'>
    <h1>Welcome to Bamboo Digital Pvt. Ltd.</h1>
    <br/>
    <p>(the "Site"), operated by Bamboo Digital Pvt. Ltd. ("us," "we," or "our"). We've created this website to assist our clients, and potential clients make informed decisions about digital marketing services.</p>
    <br/>
    <p>Before using this Site, please carefully read and understand these terms and conditions (the "Terms of Use") and our Privacy Policy. If you do not agree with these Terms of Use or the Privacy Policy, please refrain from using this Site. By browsing, accessing, linking to, using, uploading information, or downloading information from this Site, you acknowledge and agree to be bound by these Terms of Use.</p>
    <br/>
    <strong>Acceptance of Terms of Use</strong>
    <p>Using this Site, you expressly agree to these terms and conditions without any limitations or qualifications. If you disagree with these terms, please do not use this site. Bamboo Digital Pvt. Ltd. reserves the right to modify these terms and conditions without prior notice. You understand and accept that each visit to this Site is subject to the currently applicable terms of use. By accessing and browsing this site, you agree to these Terms of Use without any limitations or qualifications, and you acknowledge that any previous agreements between you and Bamboo Digital Pvt. Ltd. regarding the subject matter herein are null and void.</p>
    <br/>
    <strong>Site Usage Agreement</strong>
    <p>Using this site, you confirm that you are either at least 18 or have obtained prior consent from your parents. You also affirm that you have the legal capacity and authority to enter into this Agreement, including the ability to bind any organization you represent contractually.</p>
    <br/>
    <strong>Privacy Policy</strong>
    <p>Your site use implies your consent to collecting and utilizing this information as the Privacy Policy outlines.</p>
    <br/>
    <strong>Intellectual Property</strong>
    <p>Unless stated otherwise, all content on this Site is protected by intellectual property rights owned or licensed by Bamboo Digital Pvt Ltd. Subject to these Terms of Use, you are granted a limited, non-transferable right to access and use the Site content for personal use only. You may not sell, license, reproduce, modify, create derivative works, publicly display, or use the content for commercial purposes without Bamboo Digital's written consent. No license is granted for systematic data retrieval from this Website. All intellectual property rights are reserved.</p>
    <br/>
    <strong>Limitation on liability</strong>
    <p>Bamboo Digital, its affiliates, licensors, service providers, employees, agents, officers, and directors will not be liable for any damages, including but not limited to personal injury, loss of revenue, profits, business, or data, arising from your use of the website, linked websites, content, or services, regardless of the legal theory, even if foreseeable. This limitation does not affect any liability protected by applicable law.</p>
    <br/>
    <strong>Jurisdiction</strong>
    <p>Bamboo Digital operates this site in Mumbai, Maharashtra, India. By using this site, you agree that the terms of use and any legal actions or proceedings related to it will be governed by the laws of India, without regard to its choice of law principles. If you initiate legal proceedings against Bamboo Digital, you acknowledge that Bamboo Digital can select the preferred jurisdiction for such proceedings. By using this site and agreeing to the jurisdiction of India for legal matters, we reserve the right to defend any such actions in Mumbai, Maharashtra, regardless of your location or where you accessed the site.</p>
    <br/>
    <strong>Contact Us</strong>
    <p>If there are any questions regarding these terms of use, you may contact us using the information below:</p>
    <br/>
    <p>Email: <a href="mailto:info@bamboodigital.in">info@bamboodigital.in</a></p>


      </section>
    </div>
  )
}

export default Terms
