import React from 'react'


const We_are_card = ({bg, svg, heading, para, headingClr, paraClr }) => {
    return (
        <div className={`max-w-md p-4 md:p-8 ${bg} flex rounded-lg mb-4`}>
            <div className='px-4'>
                {svg}
            </div>
            <div>
                <p className={`${headingClr} body-text mt-1`}>
                    {heading}
                </p>
                <p className={`${paraClr} body-text mt-2`}>
                    {para}  
                </p>
            </div>
        </div>
    )
}

export default We_are_card
