import React from 'react'
import Careimg from "../assets/carimg.webp"
const CarrierHero = () => {
  return (
 <div className="md:flex items-start pt-10 ">
  <div className="md:w-1/2 text-black-1200 text-4xl md:text-4xl lg:text-4xl">
    <h1 className="text-[40px] leading-[48px]">
    Work Smart, <span className='bg-primary'>
    Grow Together</span><br className='hidden md:block'/>
Join Our Team

    </h1>
    <p className="max-w-500px  mt-5 text-gray-500 font-inter text-base font-normal leading-22 tracking--0.32">
    At Bamboo Digital, we believe in the power of talented individuals coming together to create something extraordinary. Join us and be a part of something remarkable. 

    </p>
  </div>
  <div className="md:w-1/2   ">
  <img
  src={Careimg}
  alt="careimg"
  className="mx- mt-1   my-10 w-640 h-289 md:w-350 md:h-158.047 md:flex-shrink-0  aline-center"
/>

</div>

</div>
  )
}

export default CarrierHero
