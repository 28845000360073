import React, { useState, useRef } from 'react';
import SerCard from './SerCard';
import com from "./../assets/Frame 1 (4).png";

import prod from './../assets/Frame 1 (3).png'
import media from './../assets/Frame 1 (2).png'
import tech from './../assets/Frame 1 (1).png'

const serviceData = [
  {
    url: com,
    title: `Specialized Communication and Strategy`,
    des: `Creative individuals committed to cultivating a positive brand image for businesses via communication strategies. We employ a developed roadmap to facilitate your business growth.`,
    id: "s1",
    cate: "Communications"
  },
  {
    url: prod,
    title: `Inspiring & Provoking Imagery`,
    des: `Our crew provides comprehensive end-to-end production services, guiding you through every stage to realize your brand’s vision. From concept development to final delivery, we ensure exceptional quality and efficiency, creating impactful content that captivates your audience.`,
    id: "s2",
    cate: "Productions"
  },
  {
    url: media,
    title: `Data-driven Analysis`,
    des: `A team of analysts empowers businesses to achieve exceptional growth through data-driven strategies and result-oriented campaigns. Our expertise maximizes your ROI, driving measurable results and expanding your digital presence.`,
    id: "s3",
    cate: "Media Labs"
  },
  {
    url: tech,
    title: `UI/UX Design and Web Development`,
    des: `We offer exceptional UI/UX design and web development services, combining creativity with advanced technologies to deliver digital experiences. We transform concepts into functional, engaging digital solutions.`,
    id: "s4",
    cate: "Tech"
  },
]


const SwipeableComponentMobile = () => {
  const [touchStartX, setTouchStartX] = useState(null);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const cardContainerRef = useRef(null); // Ref for the card container

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (touchStartX !== null) {
      const currentX = e.touches[0].clientX;
      const deltaX = currentX - touchStartX;

      if (deltaX > 0) {
        setSwipeDirection('right');
      } else if (deltaX < 0) {
        setSwipeDirection('left');
      }
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
    setSwipeDirection(null);
  };

  return (
    <div
      ref={cardContainerRef}
      className="swipeable-container w-full overflow-x-auto p-4"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{ scrollbarWidth: 'none' }} // Remove scrollbar in Firefox
    >
      <style>
        {`
          .swipeable-container::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      <div className="card-container flex flex-col space-x-4">
        <ul className='flex gap-2 pb-8'>
          {serviceData.map((data, index) => (
            <li  key={index}>
              <a  id={`card${data.id}`} className="botom-line" href={`#${data.id}`}>{data.cate}</a>
            </li>
          ))}
        </ul>
        <ul className='flex overflow-x-scroll flex-row' style={{marginLeft: 0}}
  onTouchStart={handleTouchStart}
  onTouchMove={handleTouchMove}
  onTouchEnd={handleTouchEnd}
>
  {serviceData.map((data) => (
    <SerCard id={data.id} img={data.url} heading={data.title} para={data.des} />
  ))}
</ul>

      </div>
    </div>
  );
};

export default SwipeableComponentMobile;


