import React from 'react';

function MissionAndValues() {
  return (
    <div>
      <strong className=' font-medium '>Mission</strong>
      <p>
        At Bamboo Digital, we craft tailored digital solutions that
        consistently deliver exceptional ROI for our brands. We strive to turn
        our clients' online presence into a compelling success story to envy
        through communication strategy, SEO optimization, paid advertising,
        production, and web design.
      </p>
      <br />
      <br />

      <strong className=' font-medium '>Values</strong>
      <p>
        At Bamboo Digital, our values define who we are and how we operate in
        the dynamic world of digital marketing.
      </p>
      <br />
      <ul>
        <li>
          <strong className=' font-medium '>Excellence:</strong>
          <br />Excellence is at our core; we relentlessly
          pursue high-quality solutions that surpass expectations.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Adaptability:</strong><br /> Adaptability is our strength as we
          embrace change and evolve alongside the digital landscape to maintain
          effective strategies.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Transparency:</strong><br /> Transparency is non-negotiable; we uphold
          open and honest communication, providing clients with clear insights
          into our processes and results.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Collaboration:</strong><br /> Collaboration is key; we harness the
          diverse talents of our team to deliver innovative solutions.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Client-centricity:</strong><br /> Client-centricity drives us; our
          client's success and satisfaction are our ultimate goals.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Accountability:</strong><br /> Accountability is integral; we take
          responsibility for our actions and results, upholding our commitment
          to clients and colleagues.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Continuous learning:</strong><br /> Continuous learning is
          fundamental; we prioritize ongoing education and development to stay
          at the forefront of industry trends.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Creativity:</strong><br /> Creativity is encouraged; we embrace
          imaginative thinking to find unique solutions.
        </li>
        <br />
        <li>
          <strong className=' font-medium '>Ethical practices:</strong><br /> Ethical practices guide us; we
          adhere to ethical and sustainable principles, respecting clients and
          the digital ecosystem.
        </li>
      </ul>

      <p>
        These values form the foundation of Bamboo Digital, guiding our every
        endeavor to deliver outstanding results for our clients.
      </p>
    </div>
  );
}

export default MissionAndValues;
