import React from 'react';
import Example from '../components/HorizontalScrollCarousel';
import LookingCard from '../components/LookingCard';
import StickyComponent from '../components/StickyComponent';
import SwipeableComponentMobile from '../components/SwipeableComponentMobile';


const Service = () => {
  React.useEffect(() => {
    document.title = 'Service - Bamboo Digital';
  }, []);

  return (
    <div className="mx-auto">
      <section className="pt-10 page-width">
        <h1 className="">
          Our tailored services can <br className='block md:hidden'/><span className='bg-primary'>empower</span><br className='hidden md:block'/> your digital presence
        </h1>
        <div className="pt-6 flex flex-row items-center gap-2">
          <button className="bg-[#8FA937] btn focus:outline-none focus:ring focus:ring-primary hover:bg-transparent hover:border border-primary rounded-full py-2 px-4 md:py-3 md:px-6 text-white">
            <a href="/contact" className="block">
              Start a project
            </a>
          </button>
          <a href="/contact" className="">
            <svg className=' w-9 md:w-10 h-auto ' width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="24" cy="24" r="23" stroke="#231F20" stroke-width="2" />
              <path d="M17 24H31M31 24L25 18M31 24L25 30" stroke="#231F20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </a>
        </div>
      </section>
      <div className=' hidden md:block'>
      <StickyComponent/>
      </div>
      <div className='block md:hidden'>
      <SwipeableComponentMobile/>
      </div>
      {/* <Example /> */}
      <div className="bg-secondary text-white py-8 md:py-12 md:mt-24">
        <section id="looking" className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 page-width">
          <LookingCard heading="Looking for something different?" des="Reach out" url="/contact" />
          <LookingCard heading="Want to see past projects?" des="Take a look" url="/works" />
          <LookingCard heading="Want to know more about us?" des="Learn more" url="/about" />
          <LookingCard heading="Have any queries in mind?" des="Contact us" url="/contact" />
        </section>
      </div>
    </div>
  );
};

export default Service;
