import React from 'react'
import Service_card from './Service_card'

const Our_service = () => {
  return (
    <div className='bg-secondary md:px-20 py-10 md:py-[100px]'>
      <div className='page-width'>
        <div className='flex items-center gap-4 pb-10 md:pb-20 '>
          <div className='w-[10px] h-[10px] rounded-full bg-primary  block md:hidden'></div>
          <h4 className='text-base md:text-[28px] text-white font-medium line-[32px] leading-[114.286%] tracking-[-0.56px] '>Our Services</h4>
        </div>
        <div className='flex gap-5 md:gap-10 flex-wrap '>
          <Service_card heading="Communications" para="Creative individuals committed to cultivating a positive brand image for businesses via communication strategies. We employ a developed roadmap to facilitate your business growth. 
" url="/services" />
          <Service_card heading="Productions" para="Our crew provides comprehensive end-to-end production services, guiding you through every stage to realize your brand’s vision. From concept development to final delivery, we ensure exceptional quality and efficiency, creating impactful content that captivates your audience.
" url="/services" />
          <Service_card heading="Tech" para="We offer exceptional UI/UX design and web development services, combining creativity with advanced technologies to deliver digital experiences. We transform concepts into functional, engaging digital solutions." url="/services" />
          <Service_card heading="Media Labs" para="A team of analysts empowers businesses to achieve exceptional growth through data-driven strategies and result-oriented campaigns. Our expertise maximizes your ROI, driving measurable results and expanding your digital presence." url="/services" />
        </div>
      </div>
    </div>
  )
}

export default Our_service
