import logo from './logo.svg';
import './App.css';
import HeroForm from './components/HeroForm';
import Navbar from './components/Navbar';
import Homes from './pages/homes';
import Service from './pages/service';
import Contact from './pages/Contact';
import Works from './pages/works';
import Footer from './components/Footer';
import CommunicationPage from './components/CommunicationPage.js';
import ProductionPage from './components/ProductionPage';
import TechPage from './components/TechPage.js';
import MediaLabsPage from './components/MediaLabsPage';

import {
  BrowserRouter as Router,
  Switch,
  Route, Routes,
  Link
} from "react-router-dom";
import About from './pages/About';
import Carrers from './pages/Carrers';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import Privacy from './pages/privacy';
import Cookie from './pages/Cookie';
import Terms from './pages/Terms';
import './../src/MobileApp.css'
import WorkDetailPage from '../src/components/WorkDetail'; // Import your WorkDetailPage component
import PageNotFound from './pages/PageNotFOund';
import WorkDetail from '../src/components/WorkDetail';
import PortfolioData from './components/PortfolioData';
import ScrollToTop from './ScrollToTop';



function App() {
 

const firebaseConfig = {
  // Your Firebase configuration object goes here
  apiKey: "AIzaSyDoraVVdEeSYyRbpmpp8vLwkRDDeOIhjdY",
  authDomain: "bamboodigitalreact.firebaseapp.com",
  projectId: "bamboodigitalreact",
  storageBucket: "bamboodigitalreact.appspot.com",
  messagingSenderId: "893092733026",
  appId: "1:893092733026:web:689a5a7402d145c64f313b",
  measurementId: "G-BHKKV97P80"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // This initializes Firestore


// const WorkPageData = [
//   {
//     id: "skift",
//     heading: "Sample Heading",
//     img1: "https://picsum.photos/200/300",
//     service: ["Service 1", "Service 2"],
//     brandName: 'Sample Brand',
//     para: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit.",],
//     sector: 'Sample Sector',
//     img2: "https://picsum.photos/200/300",
//     img3: "https://picsum.photos/200/300",
//     img4: "https://picsum.photos/200/300",
//   },
//   {
//     id: "2",
//     heading: "Another Heading",
//     img1: "https://picsum.photos/200/300",
//     service: ["Service 3", "Service 4"],
//     brandName: 'Another Brand',
//     para: ["Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."],
//     sector: 'Another Sector',
//     img2: "https://picsum.photos/200/300",
//     img3: "https://picsum.photos/200/300",
//     img4: "https://picsum.photos/200/300",
//   },
//   // Add more objects as needed
// ]



  return (
    <Router>
      <div className=' '>
        <Navbar />
        <div className=' pt-[92px]'  >
        <ScrollToTop/>
        <Routes>
          <Route exact path="/" element={<Homes/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/services" element={<Service/>}/>
          <Route exact path="/works" element={<Works/>}/>
          {/* <Route exact path="/WorkDetailPage" element={<WorkDetailPage/>} /> */}
          {/* <Route path="/work-detail/:id" element={<WorkDetailPage />} /> */}
          {PortfolioData.map((data, index)=> 
          <Route key={index} path={data.url} element={
              <WorkDetail pageHeading={data.heading} heading={data.brandName} img1={data.img1} desc={data.para} services={data.service} sector={data.sector} img2={data.img2} img3={data.img3} img4={data.img4} />
          } />
          )}
          {/* <Route path="/work-detail/petitfée" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/shilputsi" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/homes-to-life" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/casagrand" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/lodha-sterling" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/homes-to-life-instagram" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/kaya-performance-marketing" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/https://example.com/sample-project-2" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/skift" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/skift" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/skift" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} />
          <Route path="/work-detail/skift" element={<WorkDetail pageHeading={"oksr"} heading="sss"/>} /> */}
          
          <Route exact path="/carrers" element={<Carrers/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route exact path="/privacy-policy" element={<Privacy/>}/>
          <Route exact path="/cookie-policy" element={<Cookie/>}/>
          <Route exact path="/terms-of-use" element={<Terms/>}/>
          
            <Route path="/services/communication" element={<CommunicationPage />} />
            <Route path="/services/production" element={<ProductionPage />} />
            <Route path="/services/tech" element={<TechPage />} />
            <Route path="/services/media-labs" element={<MediaLabsPage />} />


          <Route path="*" element={<PageNotFound />} />
         
        </Routes>
        </div >
        <div className=' bg-[#FCFCFC]'>
        <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
