import React from 'react'
import Herosection from '../components/Herosection'
import We_are from '../components/We_are'
import Our_service from '../components/Our_service'
import Client from '../components/client'
import RecentProject from '../components/RecentProject'
import Carousel from '../components/Carousel'

const Homes = () => {
  React.useEffect(() => {
    document.title = 'Welcome - Bamboo Digital';
  }, []);
  return (
    <>
    {/* <div className='relative' > */}
    <section className='md:fixed md:left-1/2 md:transform md:-translate-x-1/2  z-[1] ' >
        <Herosection/>
    </section>
    <div className='md:mt-[666px] relative z-10 '>
    <div className=''>
      <We_are/>
    </div>
    <div>
      <Our_service/>
    </div>
    <div>
      <Client/>
    </div>
    <div>
      <RecentProject/>
      {/* <Carousel/> */}
    </div>
    </div>
    {/* </div> */}
    </>
  )
}

export default Homes
