import React from 'react'
import AutoSlider from '../components/AutoSlider'
import MenuItem from '../components/MenuItem';
import Accordin from '../components/Accordin';
import TeamCard from '../components/TeamCard';
import MissionAndValues from '../components/MissionAndValues';
import img1 from './../assets/img1.png'
import img2 from './../assets/img2.png'
import img3 from './../assets/img3.png'
import img4 from './../assets/img4.png'
import img5 from './../assets/imh5.png'
import img6 from './../assets/img6.png'

import aboutSec2 from './../assets/Group 50294.png'
import Marquee from "react-fast-marquee";

const imageUrls = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6
];
const AccordinData = [
  {
    id: "01",
    title: "Who We Are",
    content: "Founded in 2022, Bamboo Digital has quickly established itself as a steadfast digital growth partner, guiding many brands through digital uncertainty and ensuring their adaptability and long-term relevance. Our success is rooted in the values of dedication and respect for our team and clients."
  },
  {
    id: "02",
    title: "Mission and Values",
    content: <MissionAndValues/>
  },
  {
    id: "03",
    title: "Our Team",
    content: <TeamCard/>
  },
  {
    id: "04",
    title: "Our Story",
    content: "Even in the dense forests of Asia, bamboo has a remarkable ability to reach for sunlight by proliferating and becoming more assertive. They optimize their growth to thrive in a cluttered environment. Their growth pattern is incredibly efficient, making them strong and resilient. Taking inspiration from this natural phenomenon, Bamboo Digital was developed!"
  }
];
// Create an array of accordion titles
const accordionTitles = AccordinData.map((data) => (data.title));
const accordionURL = AccordinData.map((data) => (data.id));

const About = () => {
  React.useEffect(() => {
    document.title = 'About Us - Bamboo Digital';
  }, []);

  return (
    <>
      <section className='pt-10 md:pt-[100px] page-width'>
        <div className='flex items-start justify-between flex-wrap'>
          <div className='flex flex-col gap-10 w-full md:w-auto'>
            <div>
              <h1 >
                Why <span className='bg-primary'>
                We Exist
                </span>
              </h1>
            </div>
            <div className=' '>
              <div className="flex  relative">
                <MenuItem
                  title="Jump to section"
                  subMenuItems={accordionTitles}
                  url={accordionURL}
                />
              </div>
            </div>
          </div>
          <div>
            <p className=' text-sm md:text-base md:max-w-md text-text-3 pb-10 md:pb-48'>
            Bamboo Digital exists to empower businesses in the digital marketing world. We help brands grow effectively amidst competition by offering guidance and strategies for SEO optimization, Media Labs, Production, and UI/UX with consistent and sustainable solutions. In the ever-evolving digital landscape, we empower clients to thrive and adapt to changing algorithms.
            </p>
          </div>
        </div>
      </section>
      <div className='pb-20'>
      <Marquee autoFill={true} pauseOnHover={true}>
      {imageUrls.map((imgurl, index) => (
        <img className=' mx-3 md:mx-5' key={index} alt='bamboo digital' src={imgurl} />
      ))}
    </Marquee>
        {/* <AutoSlider imageUrls={imageUrls} /> */}
      </div>
      {/* <img className='w-full pb-10 md:pb-[100px]' src={aboutSec2} /> */}
      <section className='page-width'>
        {AccordinData.map((data, index) => (
          <div key={data.id}>
            <Accordin id={data.id} title={data.title} content={data.content} isLastItem={index === AccordinData.length - 1} />
          </div>
        ))}


      </section>
    </>
  )
}

export default About
