import React from 'react';
import t1 from './../assets/ShyamNew.webp'; // Assuming this is the correct image for the CEO
import t3 from './../assets/T3.webp'; // Assuming this is the correct image for the CEO
import t7 from './../assets/T7.webp'; // Assuming this is the correct image for the CEO
import t8 from './../assets/T8.webp'; // Assuming this is the correct image for the CEO
import t9 from './../assets/T9.webp'; // Assuming this is the correct image for the CEO
import t10 from './../assets/T10.webp'; // Assuming this is the correct image for the CEO
import t11 from './../assets/T11.webp'; // Assuming this is the correct image for the CEO
import t12 from './../assets/T12.webp'; // Assuming this is the correct image for the CEO
import amaanImg from './../assets/Amaan.webp'; // Assuming this is the correct image for the CEO
import furkanImg from './../assets/Furkan.webp'; // Assuming this is the correct image for the CEO
import tanishaImg from './../assets/Tanisha.webp'; // Assuming this is the correct image for the CEO
// import maazImg from './../assets/MaaazImg.webp'; // Assuming this is the correct image for the CEO
import maazImg from './../assets/maazImg.jpeg'; // Assuming this is the correct image for the CEO
import hrishantImg from './../assets/Hrishant.webp'; // Assuming this is the correct image for the CEO
import ank from './../assets/ank.webp'; // Placeholder for other team members' images
import siddesh from './../assets/siddesh.webp'; // Placeholder for other team members' images
import rucha from './../assets/Photo_Rucha.webp'; // Placeholder for other team members' images
// import hariImg from './../assets/hari.webp'; // Placeholder for othnpmer team members' images


const TeamDatas = [
  {
    id: "1",
    name: "Rishabh Bhalla",
    position: "CEO",
    img: t12
  },
  {
    id: "2",
    name: "Shruti Mohan",
    position: "COO",
    img: t11
  },
  {
    id: "3",
    name: "Ankit Arora",
    position: "Head of Copywriting & Content",
    img: ank
  },
  {
    id: "8",
    name: "Simran Arora",
    position: "Media Planner",
    img: t3
  },
  {
    id: "5",
    name: "Rucha Malhar Mutalik",
    position: "Account Management - Client Servicing",
    img: rucha
  },
  {
    id: "6",
    name: "Shyamsunder Vishwakarma",
    position: "Senior Graphic designer",
    img: t1
  },
  {
    id: "4",
    name: "Shrutika Jitendra Jogadia",
    position: "HR executive",
    img: t9
  },
  {
    id: "9",
    name: "Vivek Rawal",
    position: "Finance & Admin",
    img: t10
  },
  {
    id: "10",
    name: "Ravi Prakash Sharma",
    position: "Fullstack Developer",
    img: t7
  },
  // {
  //   id: "12",
  //   name: "Harikrishnan G",
  //   position: "UI/UX Designer",
  //   img: hariImg
  // },
  {
    id: "13",
    name: "Furkan Ashfaq Shaikh",
    position: "Machine Learning Intern",
    img: furkanImg
  },
  {
    id: "15",
    name: "Maaz Khan",
    position: "Front-End Developer Intern",
    img: maazImg
  },
  {
    id: "16",
    name: "Amaan Ansari",
    position: "Front-End Developer",
    img: amaanImg
  },
  {
    id: "17",
    name: "Tanisha Bhardwaj",
    position: "Copy & Content Writer",
    img: tanishaImg
  },
  {
    id: "18",
    name: "Siddesh Kadam",
    position: "Graphic Designer",
    img: siddesh,
  },
  {
    id: "14",
    name: "Hrishant Rajish",
    position: "Data Science Intern",
    img: hrishantImg
  },
  {
    id: "7",
    name: "Vikalp Kumar Gupta",
    position: "Content Strategist",
    img: t8
  }
]

const TeamCard = () => {

  return (
    <div className='flex items-end flex-col gap-8'>
      {/* Your TeamCard content goes here */}
      <p className=''>
        Explore the talented individuals who make our team at Bamboo Digital.
      </p>
      <div className='grid grid-cols-2 md:grid-cols-3 gap-5'>

        {TeamDatas.map((data) => (
          <div key={data.id}>
            <img className=' rounded-lg object-cover h-[200px] md:h-[400px]  w-full' width={100} height={100} src={data.img} alt={data.name} />
            <p className=' text-sm text-secondary pt-5 pb-1'>{data.name}</p>
            <p className=' text-sm text-text-3'>{data.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamCard;
