import React, { useState } from 'react';
import contactBanner from './../assets/DSC00317.jpg';
import { collection, addDoc } from "firebase/firestore";
import { db } from "./../firbase";

const Contact = () => {
    React.useEffect(() => {
        document.title = 'Contact us - Bamboo Digital';
      }, []);
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [message, setMessage] = useState("");
    const [terms, setTerms] = useState(false);

    // Function to send an email
    const sendEmail = async () => {
        const apiKey = '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'; // Replace with your actual API key
        const url = 'https://api.brevo.com/v3/smtp/email';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'api-key': apiKey,
                },
                body: JSON.stringify({
                    sender: {
                        name: 'Bamboo Digital Team',
                        email: 'info@bamboodigital.in',
                    },
                    to: [
                        {
                            email: emailAddress,
                            name: name,
                        },
                    ],
                    subject: 'Thank you for contacting us!',
                    htmlContent: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Thank You for Your Inquiry</title>
                        <style>
                            /* Add your email styles here */
                            body {
                                font-family: Arial, sans-serif;
                                background-color: #f0f0f0;
                                margin: 0;
                                padding: 0;
                            }

                            .container {
                                max-width: 600px;
                                margin: 0 auto;
                                padding: 20px;
                                background-color: #ffffff;
                                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                                border-radius: 8px;
                            }

                            h1 {
                                font-size: 24px;
                                font-weight: bold;
                                margin-bottom: 20px;
                            }

                            p {
                                font-size: 16px;
                                line-height: 1.5;
                                margin-bottom: 20px;
                            }

                            .contact-info {
                                margin-top: 20px;
                                font-size: 14px;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <h1>Dear ${name},</h1>
                            <p>We have received your message and will get back to you as soon as possible.</p>
                            <div class="contact-info">
                                <p>Best Regards,</p>
                                <p>Bamboo Digital Pvt. Ltd.</p>
                                <p>Mob. +91 91364 02590</p>
                                <p>Email: info@bamboodigital.in</p>
                            </div>
                        </div>
                    </body>
                    </html>
                    `,
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                // Handle success
                // console.log('Email sent:', responseData);
            } else {
                // Handle error
                // console.error('Error sending email:', responseData);
            }
        } catch (error) {
            // Handle any errors here
            // console.error('Error sending email:', error);
        }
    };

    const sendEmailToContact = async () => {
        const apiKey = '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'; // Replace with your actual API key
        const url = 'https://api.brevo.com/v3/smtp/email';

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'api-key': apiKey,
                },
                body: JSON.stringify({
                    sender: {
                        name: 'Bamboo Digital Team',
                        email: 'info@bamboodigital.in',
                    },
                    to: [
                        {
                            name: 'Bamboo Digital Team',
                            email: 'ravi@bamboodigital.in',
                        },
                    ],
                    subject: 'You Have A New Enquiry For Bamboo Digital!',
                    htmlContent: `<!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Thank You for Your Inquiry</title>
                        <style>
                            /* Add your email styles here */
                            body {
                                font-family: Arial, sans-serif;
                                background-color: #f0f0f0;
                                margin: 0;
                                padding: 0;
                            }

                            .container {
                                max-width: 600px;
                                margin: 0 auto;
                                padding: 20px;
                                background-color: #ffffff;
                                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                                border-radius: 8px;
                            }

                            h1 {
                                font-size: 24px;
                                font-weight: bold;
                                margin-bottom: 20px;
                            }

                            p {
                                font-size: 16px;
                                line-height: 1.5;
                                margin-bottom: 20px;
                            }

                            .contact-info {
                                margin-top: 20px;
                                font-size: 14px;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="container">
                            <h1>Dear team,</h1>
                            <p>A new enquiry arrived</p>
                            <div class="contact-info">
                                <p>
                               ${name}
    ${companyName}
    ${contactNumber}
 ${emailAddress}
    ${message}
                                </p>
                                
                            </div>
                        </div>
                    </body>
                    </html>
                    `,
                }),
            });

            const responseData = await response.json();

            if (response.ok) {
                // Handle success
                console.log('Email sent:', responseData);
            } else {
                // Handle error
                console.error('Error sending email:', responseData);
            }
        } catch (error) {
            // Handle any errors here
            console.error('Error sending email:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (terms) {
            try {
                const docRef = await addDoc(collection(db, "contacts"), {
                    name: name,
                    companyName: companyName,
                    contactNumber: contactNumber,
                    emailAddress: emailAddress,
                    message: message
                });

                // Call sendEmail function to send an email
                await sendEmail();
                await sendEmailToContact();

                // console.log("Document written with ID: ", docRef.id);
                setName("");
            setCompanyName("");
            setContactNumber("");
            setEmailAddress("");
            setMessage("");
            setTerms(false);

            } catch (e) {
                // console.error("Error adding document: ", e);
            }
        } else {
            alert("Please agree to the terms and conditions before submitting the form.");
        }
    };

    return (
        <section>
            <div className='flex items-end justify-center gap-5 md:justify-between py-10 md:py-[6.25rem] page-width flex-wrap '>
                <h1>
                    Bamboo Digital Agency: Mumbai
                    <br />
                    Roots, Worldwide Reach –<br />
                    Your Location, Our Expertise.
                </h1>
                <a href='#contactForm' className='rounded-full border-2 border-text-3 p-3'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 4.99902V18.999M12 18.999L18 12.999M12 18.999L6 12.999" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

                </a>
            </div>
            <div className=' page-width mb-20 rounded-[20px] bg-[#F3F4F6]'>
                <img style={{borderTopRightRadius: "3%",borderTopLeftRadius: "3%"}} src={contactBanner} />
                <div className=' p-4 md:p-20 gap-5 flex md:gap-[10.625rem] flex-wrap md:flex-nowrap '>
                    <div>
                        <div className='pb-3 md:pb-10'>
                            <p className='body-text text-text-3 pb-2' >Have a project in<br />mind ?</p>
                            <a href='mailto:info@bamboodigital.in'>info@bamboodigital.in</a>
                        </div>
                        <div>
                            <p className='body-text text-text-3 pb-2' >Call us at</p>
                            <a href='tel:+919136402590'>+91 91364 02590</a>
                        </div>
                    </div>
                    <div>
                        <h4 className=' text-base md:text-[28px] pb-5 md:pb-10'>
                            Curious to learn more or collaborate ?
                            <br />
                            Contact us today.
                        </h4>

                        <form id="contactForm" onSubmit={handleSubmit}>
                            <div className=' block md:inline-flex flex-col pb-7 md:w-1/2 md:pr-10'>
                                <label className='dotsForms  text-lime-700 text-sm font-normal body-text' htmlFor="Name">Name</label>

                                <input required type="text" id="Name" name="Name" placeholder="Walter White" className="form-input w-full md:w-auto rounded-lg border px-3 py-[1.125rem]" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className='block md:inline-flex flex-col pb-7 md:w-1/2'>
                                <label className=' dotsForms text-lime-700 text-sm font-normal' htmlFor="companyName">Company Name</label>
                                <input required type="text" id="companyName" name="companyName" placeholder="Gray Matter Technologies" className="form-input w-full md:w-auto" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                            </div>
                            <div className='block md:inline-flex flex-col pb-7 md:w-1/2 md:pr-10'>
                                <label className='text-lime-700 dotsForms text-sm font-normal' htmlFor="contactNumber">Contact Number</label>
                                <input required type="text" id="contactNumber" name="contactNumber" placeholder="+31 12345 67890" className="form-input w-full md:w-auto" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                            </div>
                            <div className='block md:inline-flex flex-col pb-7 md:w-1/2'>
                                <label className='text-lime-700 text-sm dotsForms font-normal' htmlFor="emailAddress">Email Address</label>
                                <input required type="email" id="emailAddress" name="emailAddress" placeholder="typeyouremail@example.com" className="form-input w-full md:w-auto" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                            </div>
                            <div className='flex flex-col pb-7'>
                                <label className='text-lime-700 text-sm font-normal dotsForms' htmlFor="message">Message</label>
                                <textarea required id="message" name="message" placeholder="Tell us your message" className="form-input" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <div className='flex flex-col pb-10'>
                                <div className='flex items-start gap-[.625rem]'>
                                    <input required type="checkbox" id="terms" name="terms" className="mt-1 inline-block" checked={terms} onChange={(e) => setTerms(e.target.checked)} />
                                    <label className='text-lime-700 text-sm font-normal inline-block' htmlFor="terms">I agree to the processing of my (personal) data for contacting me. I also agree to the retention of my data so that I can be contacted for possible future contact. Processing takes place in accordance with Bamboo Digital's privacy policy.</label>

                                </div>
                            </div>
                            <button type="submit" className="btn focus:outline-none focus:ring focus:ring-primary hover:bg-transparent hover:border border-primary px-6 py-3 w-full bg-[#8FA937] rounded-full text-white font-medium ">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
