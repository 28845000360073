import React from 'react'

const Privacy = () => {
  React.useEffect(() => {
    document.title = 'Privacy Policy - Bamboo Digital';
  }, []);
  return (
    <div className=' pt-10  md:pt-[100px]'>
      <section className='page-width'>

            <h1>Privacy Policy</h1>
            <br/>
            <p>Our Privacy Policy describes how we collect, use, and share your personal data.</p>
            <br/>
            <p><strong>Bamboo Digital Pvt. Ltd.</strong> gathers only the essential information about you necessary to deliver a quality service.</p>
            <br/>
            <p>This policy outlines the procedures through which your data may be gathered regarding your interactions. By using this website, you authorize us to collect this information. You can familiarize yourself with our privacy practices and contact us if you have questions.</p>
            <br/>
            <strong>What Do We Mean by Personal Data?</strong>
            <br/>
            <p>As a marketing agency, we uphold an individual’s privacy rights. That’s why we classify any data associated with an identifiable individual, whether directly or indirectly, as 'personal data.' This encompasses data that directly identifies you, like your name, and data that, while not directly identifying, could reasonably be used for your identification, such as your device's serial number. In contrast, aggregated data falls under the category of non-personal data within the scope of this Privacy Policy.</p>
            <br/>
            <strong>Privacy at Bamboo Digital Pvt. Ltd: Your Rights & Our Commitment</strong>
            <br/>
            <strong>Privacy Everywhere:</strong>
            <br/>
            <p>This policy covers personal data handling by us across our website, Social Media handles like Instagram, Facebook, LinkedIn, and X, and in-person interactions. When we link to third parties or provide third-party apps, their privacy policies apply, so know your rights before engaging with them.</p>
            <br/>
            <strong>Your Privacy Rights:</strong>
            <br/>
            <p>We respect your right to know, access, correct, transfer, restrict, and delete your data. We've extended these rights to all our customers worldwide. If you exercise these rights, expect fair treatment and the same level of service. You can withdraw consent whenever needed.</p>
            <br/>
            <strong>Privacy Limitations</strong>
            <br/>
            <p>While we aim to fulfill your requests, legal obligations may prevent us, like retaining transaction data per law. We also can't compromise others' privacy, entertain frivolous requests, or those that harm security.</p>
            <br/>
            <strong>Information Collected</strong>
            <br/>
            <p>We may gather information as needed for specific transactions, which could include your name, address, phone number, email, and data related to your website usage. Additional information required to fulfill requests may be collected as specified on the website.</p>
            <br/>
            <strong>Information Use</strong>
            <br/>
            <p>We primarily utilize the collected information to facilitate the purpose for which you accessed the website. We take all necessary precautions to secure this data against unauthorized access. In some cases, to access your account information, you may be asked to provide additional forms of identification for added security.</p>
            <br/>
            <strong>Cookies</strong>
            <br/>
            <p>Your web browser has a built-in feature to store tiny data files known as 'cookies,' which store information enabling websites to recognize your account. Our website uses these cookies to improve your browsing experience. While you can disable cookie acceptance on your computer, please note that doing so may affect the functionality of certain features on the website.</p>
            <br/>
            <strong>Disclosing Information</strong>
            <br/>
            <p>We do not disclose any personal information obtained about you from this website to third parties unless you permit us to do so by ticking the relevant boxes in registration or competition forms.</p>

            <br/>
            <strong>Changes to Privacy Policy</strong>
            <br/>
            <p>Any changes to our Privacy Policy will be updated here and will supersede this version of our policy. We will take reasonable steps to draw your attention to any changes in our policy. However, to be safe, we suggest you read this document each time you use the website to ensure that it still meets your approval.</p>
            <br/>
            <strong>Contacting Us</strong>
            <br/>
            <p>If you have any questions about our Privacy Policy, please email us at <a href="mailto:info@bamboodigital.in">info@bamboodigital.in</a>. You can also correct any factual errors in that information or require us to remove your details from any list under our control.</p>
        </section>
    </div>
  )
}

export default Privacy

